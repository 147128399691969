import _ from 'lodash';
import safeJsonStringify from 'safe-json-stringify';
import { isSameDay, isYesterday, isAfter, format, sub, set } from 'date-fns';
import { DEFAULT_TIME_FORMAT, DAY_OF_WEEK, DEFAULT_DATE_TIME_FORMAT, SHORT_DATE_OF_MONTH } from './date-util';

declare const CSA_ENV: any;

export interface IdName {
  id: string;
  name: string;
  type?: string;
}

export class Util {
  public static getEnvVar(varName: string, debug: boolean = false, doNotFailIfMissing: boolean = false): string {
    let csa: any = {};
    try {
      csa = CSA_ENV;
    } catch (e) {
      /*eslint-disable*/
      console.error('CSA_ENV not defined. please run csa set-ui-env');
    }
    let val = csa[varName];
    if (debug) {
      console.log('util.getEnvVar', `reading process.env.${varName}`);
    }
    if (!val) {
      if (process.env[varName]) {
        /*eslint-disable*/
        // console.warn(`WARNING: ${varName} is not defined in CSA_ENV. Using process.env.${varName}`);
        val = process.env[varName];
      }
    }
    if (!val && !doNotFailIfMissing) {
      /*eslint-disable*/
      console.error('util.getEnvVar', `${varName} not defined. Env settings are: ${Util.safeStringIfy(CSA_ENV)}`);
      const msg = `env.${varName} is missing`;
      throw new Error(msg);
    }
    return val;
  }

  public static safeStringIfy(o: any): string {
    return safeJsonStringify(o, null, 2);
  }
  public static parseIfNeeded(input: any): any {
    if (typeof input === 'string') {
      return JSON.parse(input);
    }
    return input;
  }

  public static getContentRequestHeader(): any {
    const token = window.localStorage['formioToken'];
    if (!token) {
      console.error('getContentRequestHeader', 'No token found in localStorage');
      return {};
    }

    return { 'x-jwt-token': token };
    // Looks like form.io forces into storing token in localStorage https://help.form.io/developers/auth#authentication-flow (maybe there are alternatives)
  }

  public static parseQueryString(searchString: string, key: string): string | undefined {
    const query = searchString.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === key) {
        return decodeURIComponent(pair[1]);
      }
    }
    return undefined;
  }

  // https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript
  public static createCookie(name: string, value: string, days: number): void {
    let expires: string;
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    } else {
      expires = '';
    }
    document.cookie = name + '=' + value + expires + '; path=/';
  }

  public static readCookie(name: string): string | null {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  public static eraseCookie(name: string) {
    Util.createCookie(name, '', -1);
  }

  public static getContentUrl(contentType: 'image' | 'json-upload'): string {
    switch (contentType) {
      case 'image':
        return `${Util.getEnvVar('REACT_APP_CSA_CONTENT')}/image`;
      case 'json-upload':
        return `${Util.getEnvVar('REACT_APP_CSA_CONTENT')}/json`;
      default:
        throw new Error(`Unknown contentType ${contentType}`);
    }
  }

  public static safeParse(val: string | undefined | null, logError: boolean = true): any {
    try {
      if (typeof val === 'string') {
        return JSON.parse(val);
      }
      return val;
    } catch (e) {
      if (logError) {
        console.error('safeParse', `Could not parse ${val}`, e);
        return val;
      }
    }
  }

  public static convertBlanks(obj: any, from: '' | null | undefined, to: '' | null | undefined): any {
    const result: any = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] === from) {
        result[key] = to;
      } else {
        result[key] = obj[key];
      }
    });
    return result;
  }

  public static displayName(
    objWithName:
      | {
          title: string | null | undefined;
          firstName: string | null | undefined;
          middleName: string | null | undefined;
          lastName: string | null | undefined;
        }
      | null
      | undefined
  ): string {
    if (!objWithName) {
      return '';
    }
    const name = `${objWithName.title || ''} ${objWithName.firstName || ''} ${objWithName.middleName || ''} ${
      objWithName.lastName || ''
    }`.trim();
    return name.replace(/\s\s+/g, ' ');
  }

  public static idNamePairsFromEnum(enumeration: any): IdName[] {
    return Object.keys(enumeration).map((key) => {
      return { id: key, name: Util.enumDisplayName(key) };
    });
  }
  public static enumDisplayName(enumText: string): string {
    return _.startCase(enumText.toLowerCase());
  }

  public static idNamePairsFromArray(array: any): IdName[] {
    return array.map((element: any) => {
      return { id: element, name: element };
    });
  }

  public static dateStamp(date: string): string {
    const msgDate = new Date(date);
    const sameDay = isSameDay(msgDate, new Date());
    return sameDay ? format(msgDate, DEFAULT_TIME_FORMAT) : format(msgDate, DEFAULT_DATE_TIME_FORMAT);
  }

  /*
    Date is same day --> Time of message 
    Date is yesterday anytime --> 'Yesterday'
    Last 7 days --> day of week as 'Mon', 'Tue' etc.
    All else --> date ex. Sep 10th 2022
  */
  public static dateChatStamp(date: string): string {
    const msgDate = new Date(date);
    const sameDay = isSameDay(msgDate, new Date());
    const yesterday = isYesterday(msgDate);
    const sevenDaysAgo = set(sub(new Date(), { days: 7 }), { hours: 23, minutes: 59, seconds: 59, milliseconds: 9999 });
    const isWithinSevenDays = isAfter(msgDate, sevenDaysAgo);

    if (sameDay) {
      return format(msgDate, DEFAULT_TIME_FORMAT);
    } else if (yesterday) {
      return 'Yesterday';
    } else if (isWithinSevenDays) {
      return format(msgDate, DAY_OF_WEEK);
    }

    return format(msgDate, SHORT_DATE_OF_MONTH);
  }

  public static checkValidEmailDomain(orgEmailDomain: any, type: string | undefined, email: string): boolean {
    if (!type || !orgEmailDomain || !orgEmailDomain[type]) {
      return true;
    }
    if (!!orgEmailDomain[type] && !email) {
      return false;
    }
    const enteredDoamin = email.split('@').pop();
    return orgEmailDomain[type] === enteredDoamin;
  }
}

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const PHONE_REGEX = /^\d{10}$/;
export const STRIP_PHONE = /[+ ()-]/g;
export const STRIP_HYPHENS = /-/g;
export const SEARCH_DEBOUNCE = 1000;
export const SEARCH_MINCHAR = 3;

import React, { useState, useEffect, useContext } from 'react';
import { SelectorIdName, SEVERITY, ToastDispatchContext, Util } from 'ui-lib';
import { Button, Grid, Typography, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { Org, useOrgService } from 'lib/api/use-org-service';
import { useErrorHandler } from 'lib/use-error-handler';
import { OrgType } from 'lib/api/api-types';
import { ROUTES } from '../../Routes';
import { OrgAuthConfigType, AuthConfig, OrgAuthConfig } from 'verid-shared-lib';
import { useAuthService } from '../../lib/api/use-auth-service';
import { ClientLogger } from 'lib/client-logger';

const DEBUG = false;

const useClasses = makeStyles({
  btnSpacing: {
    margin: '6px auto',
    width: '80%',
    fontWeight: 'bold',
  },
  loginBtns: {
    width: '80%',
    display: 'flex',
    justifyContent: 'space-around',
  },
});

let callbackURL: string;
let currentAppId: string;

export function LoginOrgsList() {
  const classes = useClasses();
  const navigate = useNavigate();
  const authApi = useAuthService();
  const errorHandler = useErrorHandler('LoginOrgsList');
  const orgService = useOrgService();
  const [orgsSelectList, setOrgsSelectList] = useState<SelectorIdName[]>([]);
  const [orgsList, setOrgsList] = useState<{ __typename: 'Org'; id: string; name: string; authConfig: any }[]>([]);
  const toastDispatch = useContext(ToastDispatchContext);
  const location = useLocation();

  // TODO fix setOrgsList(orgs) orgs type issue.
  // useEffect(() => {
  //   const queryStrings = window.location.search;
  //   callbackURL = Util.parseQueryString(queryStrings, 'callbackURL') || Util.getEnvVar('REACT_APP_VERID_UI');
  //   currentAppId = Util.parseQueryString(queryStrings, 'currentAppId') || '';
  //   orgService
  //     .getAllOrgs(OrgType.PROVIDER)
  //     .then((resp) => {
  //       const orgs = (resp && resp.data && resp.data.getAllOrgs) || [];
  //       setOrgsList(orgs);
  //       const orgsSelectList: SelectorIdName[] = orgs.map((org) => ({ id: org.id, name: org.name }));
  //       setOrgsSelectList(orgsSelectList);
  //     })
  //     .catch((error) => {
  //       ClientLogger.error('LoginOrgsList.useEffect.getAllOrgs', error);
  //     });
  //   toastDispatch({ severity: SEVERITY.INFO, msg: `Staff would use hospital credentials & User would use patient portal credentials` });
  // }, []);

  const getAuthUrl = (authConfig: AuthConfig, state: string): string => {
    return (
      authConfig.authorizationEndpoint +
      `?client_id=${authConfig.clientId}` +
      `&redirect_uri=${Util.getEnvVar('REACT_APP_VERID_API_HOST')}/redirect` +
      `&state=${state}` +
      '&scope=openid profile email' +
      '&response_type=code' +
      '&response_mode=form_post' +
      '&nonce=roles'
    );
  };

  const onClickLogin = async (authConfig: AuthConfig | null, currentOrgId: string, roles: string[], authName: string) => {
    if (authConfig === null) {
      toastDispatch({ severity: SEVERITY.ERROR, msg: `Org Auth is not completely configured` });
      return;
    }
    if (authConfig.type === OrgAuthConfigType.LOCALLY_PROVIDED || authConfig.type === OrgAuthConfigType.EXTERNALLY_PROVIDED) {
      //Need to configure and after validate user should navigate to their initiate place
      navigate(ROUTES.LOGIN + `?currentAppId=${currentAppId || currentOrgId}`);
    } else if (authConfig.type === OrgAuthConfigType.OPEN_ID) {
      //CurrentOrgId would be come from initiate app or select org
      const redirect = getAuthUrl(authConfig, JSON.stringify({ currentOrgId, currentAppId, roles, authName, callbackURL }));
      window.location.assign(redirect);
    }
  };
  return (
    <>
      <Grid container justifyContent="center">
        <Grid container item xs={12} md={6} direction="column" alignItems="center">
          <Typography variant="h2" color="textPrimary">
            Organizations List
          </Typography>
          {orgsList.map((org) => {
            return (
              <>
                <Button key={org.id} variant="contained" fullWidth={true} color="primary" className={classes.btnSpacing}>
                  {org.name}
                </Button>
                <div className={classes.loginBtns}>
                  {org.authConfig &&
                    org.authConfig.map((orgAuthConfig: OrgAuthConfig) => {
                      return (
                        <Button
                          key={orgAuthConfig.authName}
                          color="primary"
                          variant="contained"
                          disabled={orgAuthConfig.authConfig === null}
                          onClick={() => onClickLogin(orgAuthConfig.authConfig, org.id, orgAuthConfig.veridRoles, orgAuthConfig.authName)}
                        >
                          {orgAuthConfig.authName}
                        </Button>
                      );
                    })}
                </div>
              </>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
}

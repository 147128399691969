import { LayoutHamburgerMenuBar } from 'components/layout/LayoutHamburgerMenuBar';
import React, { useContext, useState, useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { ROUTES } from 'Routes';
import { UserContext } from 'lib/auth/user-context';
import { ClientLogger } from 'lib/client-logger';
import { ConfirmDialog, SEVERITY, ToastDispatchContext, Util } from 'ui-lib';
import { useAuthService } from 'lib/api/use-auth-service';
import { useErrorHandler } from 'lib/use-error-handler';

const DEBUG = false;

const useClasses = makeStyles({
  btnSpacing: {
    margin: '6px auto',
    width: '80%',
    fontWeight: 'bold',
  },
});

export function Home() {
  const classes = useClasses();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const errorHandler = useErrorHandler('Home');
  const authService = useAuthService();
  const isJurisdictionAdmin = userContext.$.isJurisdictionAdmin();
  const isOrgAdmin = userContext.$.isOrgAdmin();
  const [showConfirmDeleteDBDialog, setShowConfirmDeleteDBDialog] = useState<boolean>(false);
  DEBUG && ClientLogger.debug('Home.tsx:', 'render', { userContext, isJurisdictionAdmin });
  const toastDispatch = useContext(ToastDispatchContext);

  useEffect(() => {
    const queryStrings = window.location.search;
    const errorMessage = Util.parseQueryString(queryStrings, 'errorMessage') || '';
    if (errorMessage) {
      toastDispatch({
        severity: SEVERITY.ERROR,
        msg: errorMessage,
      });
    }
  });

  const confirmDeleteDB = async (confirmed: boolean, verifiableInput: string) => {
    setShowConfirmDeleteDBDialog(false);
    const [branch, comment] = verifiableInput?.split(',');
    if (confirmed && branch && comment) {
      const resetData = await authService.resetData(comment, branch);
      if (resetData.data) {
        toastDispatch({
          severity: SEVERITY.SUCCESS,
          msg: 'Data reset successfully',
        });
      } else {
        errorHandler.handleErrors({ graphQLErrors: resetData.errors });
      }
    } else {
      toastDispatch({
        msg: `Delete aborted - missing branch name or comment missing. Branch name provided: ${branch}
        Comment provided: ${comment}`,
        severity: SEVERITY.WARNING,
      });
    }
  };

  return (
    <>
      {userContext.userState.isLoggedIn ? (
        <LayoutHamburgerMenuBar>
          <Grid container justifyContent="center">
            <Grid container item xs={12} md={6} direction="column" alignItems="center">
              {isJurisdictionAdmin && (
                <Button
                  variant="contained"
                  fullWidth={true}
                  color="primary"
                  component={RouterLink}
                  to={ROUTES.JURISDICTION_LIST}
                  className={classes.btnSpacing}
                >
                  Jursidictions
                </Button>
              )}
              {isJurisdictionAdmin && (
                <Button
                  variant="contained"
                  fullWidth={true}
                  color="primary"
                  component={RouterLink}
                  to={ROUTES.APP_LIST}
                  className={classes.btnSpacing}
                >
                  App Organizations
                </Button>
              )}
              {isJurisdictionAdmin && (
                <Button
                  variant="contained"
                  fullWidth={true}
                  color="primary"
                  component={RouterLink}
                  to={ROUTES.PROVIDER_ORG_LIST}
                  className={classes.btnSpacing}
                >
                  Provider Organizations
                </Button>
              )}
              {isOrgAdmin && (
                <Button
                  variant="contained"
                  fullWidth={true}
                  color="primary"
                  component={RouterLink}
                  to={ROUTES.PERSON_ORG_LIST}
                  className={classes.btnSpacing}
                >
                  People
                </Button>
              )}
              {isOrgAdmin && (
                <Button
                  variant="contained"
                  fullWidth={true}
                  color="primary"
                  component={RouterLink}
                  to={ROUTES.TEAM_LIST}
                  className={classes.btnSpacing}
                >
                  Teams
                </Button>
              )}
              {isOrgAdmin && (
                <Button
                  variant="contained"
                  fullWidth={true}
                  color="primary"
                  component={RouterLink}
                  to={ROUTES.VERIFICATION_METHOD_LIST}
                  className={classes.btnSpacing}
                >
                  Verification Methods
                </Button>
              )}
              {isOrgAdmin && (
                <Button
                  variant="contained"
                  fullWidth={true}
                  color="primary"
                  component={RouterLink}
                  to={ROUTES.AGREEMENT_LIST}
                  className={classes.btnSpacing}
                >
                  Agreements
                </Button>
              )}
              {isJurisdictionAdmin && (
                <Button
                  variant="contained"
                  fullWidth={true}
                  color="primary"
                  component={RouterLink}
                  to={ROUTES.DESIGNATION_LIST}
                  className={classes.btnSpacing}
                >
                  Designations
                </Button>
              )}
              {isJurisdictionAdmin && (
                <>
                  <Button
                    variant="contained"
                    fullWidth={true}
                    color="primary"
                    component={RouterLink}
                    to={ROUTES.ID_TYPE_LIST}
                    className={classes.btnSpacing}
                  >
                    Identification Types
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth={true}
                    color="primary"
                    className={classes.btnSpacing}
                    onClick={() => {
                      setShowConfirmDeleteDBDialog(true);
                    }}
                  >
                    Nuke All Data
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
          <ConfirmDialog
            title="Delete all data?"
            message="You are about to delete all data in this environment. Please enter the environment name followed by a comma
        and a comment to proceed. Example: 'adamdev,deleting DB because it's corrupt'"
            showDialog={showConfirmDeleteDBDialog}
            confirmed={(confirm, verifiableInput) => confirmDeleteDB(confirm, verifiableInput || '')}
            btnCancel="Cancel"
            btnConfirm="Confirm"
            isVerifiableInput={true}
          />
        </LayoutHamburgerMenuBar>
      ) : (
        <Grid container flexDirection="column" alignItems="center">
          <Grid container item xs={12} sm={6} justifyContent="center">
            <Box my={6}>
              <Typography variant="h5" align="center">
                Welcome.
              </Typography>
            </Box>
          </Grid>
          <Grid container item xs={12} sm={6}>
            <Button variant="contained" color="primary" className={classes.btnSpacing} onClick={() => navigate(ROUTES.LOGIN)}>
              Login
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Field, Formik, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import { useOrgService } from 'lib/api/use-org-service';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import { IdName, Util, Selector, Switch } from 'ui-lib';
import React, { SyntheticEvent, useRef, useState } from 'react';
import { dialogClasses } from '../../style/sharedCssClasses';
import CloseIcon from '@mui/icons-material/Clear';
import {
  ExternalMessageConfig,
  MessageTemplates,
  MailOptions,
  MessageTemplate,
  defaultExtMessageConfig,
  insertIdsIntoMessageConfig,
} from 'verid-shared-lib';

interface IValues extends ExternalMessageConfig {}

interface IProps {
  editingId: string; // if editing an existing org, this is the id. Otherwise we're creating a new one
  orgName: string;
  onClose: () => void;
  initialValues: IValues | undefined;
}

const DEBUG = false;

function AuthConfigTypeSelector(props: { name: string }) {
  const choices: IdName[] = Util.idNamePairsFromEnum(MessageTemplates);

  return <Selector name={props.name} items={choices} label="Authentication Type" />;
}

function EditMailConfig(props: {
  baseName: string;
  values: MailOptions;
  title: string;
  panelId: string;
  panelOpen: string | false;
  openPanel: (panel: string) => void;
}) {
  const { baseName, values, title, panelId, panelOpen, openPanel } = props;
  return (
    <>
      <Accordion expanded={panelOpen === panelId} onChange={() => openPanel(panelId)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Field
            component={TextField}
            name={`${baseName}.host`}
            label="Host"
            placeholder="IP or URL of host"
            type="text"
            fullWidth={true}
            variant="outlined"
            sx={dialogClasses.inputField}
          />
          <Field
            component={TextField}
            name={`${baseName}.port`}
            label="Port"
            placeholder="Enter HTTP port"
            type="number"
            fullWidth={true}
            variant="outlined"
            sx={dialogClasses.inputField}
          />
          <Switch name={`${baseName}.secure`} label="Secure" />
          <Field
            component={TextField}
            name={`${baseName}.auth.user`}
            label="User Name"
            placeholder="Enter user name to use to login to mail server"
            type="text"
            fullWidth={true}
            variant="outlined"
            sx={dialogClasses.inputField}
          />
          <Field
            component={TextField}
            name={`${baseName}.auth.user`}
            label="Password"
            placeholder="Enter password to use to login to mail server (leave blank for no change)"
            type="password"
            fullWidth={true}
            variant="outlined"
            sx={dialogClasses.inputField}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
function MessageTemplateConfig(props: {
  baseName: string;
  values: MessageTemplate;
  title: string;
  panelId: string;
  panelOpen: string | false;
  openPanel: (panel: string) => void;
}) {
  const { baseName, values, title, panelId, panelOpen, openPanel } = props;
  DEBUG && ClientLogger.debug('MessageTemplateConfig', 'render', values);
  return (
    <>
      <Accordion expanded={panelOpen === panelId} onChange={() => openPanel(panelId)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Field
            component={TextField}
            name={`${baseName}.subject`}
            label="Subject (email only)"
            placeholder="Subject of email"
            type="text"
            fullWidth={true}
            variant="outlined"
            sx={dialogClasses.inputField}
          />
          <Field
            component={TextField}
            name={`${baseName}.plainText`}
            label="Plain Text"
            placeholder="Plan text version of email"
            type="text"
            fullWidth={true}
            variant="outlined"
            sx={dialogClasses.inputField}
          />
          <Switch name={`${baseName}.useHTMLTemplate`} label="Use Master Template" />
          {!values.templateText.en.useHTMLTemplate ? (
            <Field
              component={TextField}
              name={`${baseName}.htmlText`}
              label="HTML Template"
              placeholder="Enter template HTML for email"
              type="text"
              fullWidth={true}
              variant="outlined"
              sx={dialogClasses.inputField}
            />
          ) : (
            <>
              <Field
                component={TextField}
                name={`${baseName}.htmlTemplate.preHeader`}
                label="Pre Header HTML"
                placeholder=""
                type="text"
                fullWidth={true}
                variant="outlined"
                sx={dialogClasses.inputField}
              />
              <Field
                component={TextField}
                name={`${baseName}.htmlTemplate.beforeCTA`}
                label="Before CTA HTML"
                placeholder=""
                type="text"
                fullWidth={true}
                variant="outlined"
                sx={dialogClasses.inputField}
              />
              <Field
                component={TextField}
                name={`${baseName}.htmlTemplate.ctaText`}
                label="Call to Action Text"
                placeholder=""
                type="text"
                fullWidth={true}
                variant="outlined"
                sx={dialogClasses.inputField}
              />
              <Field
                component={TextField}
                name={`${baseName}.htmlTemplate.ctaUrl`}
                label="Call to Action URL"
                placeholder="E"
                type="text"
                fullWidth={true}
                variant="outlined"
                sx={dialogClasses.inputField}
              />
              <Field
                component={TextField}
                name={`${baseName}.htmlTemplate.afterCTA`}
                label="After CTA HTML"
                placeholder="E"
                type="text"
                fullWidth={true}
                variant="outlined"
                sx={dialogClasses.inputField}
              />
              <Field
                component={TextField}
                name={`${baseName}.htmlTemplate.companyNameAndAddress`}
                label="Company Name and Address"
                placeholder="E"
                type="text"
                fullWidth={true}
                variant="outlined"
                sx={dialogClasses.inputField}
              />
              <Field
                component={TextField}
                name={`${baseName}.htmlTemplate.unsubscribeText`}
                label="Unsubscribe Text"
                placeholder="E"
                type="text"
                fullWidth={true}
                variant="outlined"
                sx={dialogClasses.inputField}
              />
              <Field
                component={TextField}
                name={`${baseName}.htmlTemplate.unsubscribeUrl`}
                label="Unsubscribe URL"
                placeholder="E"
                type="text"
                fullWidth={true}
                variant="outlined"
                sx={dialogClasses.inputField}
              />
              <Field
                component={TextField}
                name={`${baseName}.htmlTemplate.unsubscribeButtonText`}
                label="Unsubscribe Button Text"
                placeholder="E"
                type="text"
                fullWidth={true}
                variant="outlined"
                sx={dialogClasses.inputField}
              />
              <Field
                component={TextField}
                name={`${baseName}.htmlTemplate.logoUrl`}
                label="Logo URL"
                placeholder="E"
                type="text"
                fullWidth={true}
                variant="outlined"
                sx={dialogClasses.inputField}
              />
            </>
          )}
          <Accordion expanded={panelOpen === panelId} onChange={() => openPanel(panelId)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h5">Input Ids</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Field
                component={TextField}
                name={`${baseName}.messageInputIds`}
                label="Input Ids"
                placeholder=""
                type="text"
                disabled={true}
                fullWidth={true}
                variant="outlined"
                sx={dialogClasses.inputField}
              />
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export function EditExtMessageConfig(props: IProps) {
  const orgService = useOrgService();
  const errorHandler = useErrorHandler('EditOrg');
  const formRef = useRef<FormikProps<IValues>>(null);
  const dialogTitle = 'External Message Config';
  const [panelOpen, setPanelOpen] = useState<string | false>(false);

  async function save(values: IValues) {
    const saveValues = insertIdsIntoMessageConfig(values);
    const resp = await orgService.orgUpdate(props.editingId, { extMessageConfig: saveValues });
    DEBUG && ClientLogger.debug('EditExtMessageConfig', 'update org', { resp, saveValues, values });
    if (resp.data?.orgUpdate.id) {
      props.onClose();
    } else {
      errorHandler.handleErrors({ graphQLErrors: resp.errors });
    }
  }

  const openPanel = (panel: string) => (event: SyntheticEvent, isOpen: boolean) => {
    setPanelOpen(isOpen ? panel : false);
  };

  DEBUG && ClientLogger.debug('EditOrgAuthCondig', 'render', { props, formRef: JSON.stringify(formRef.current) });
  return (
    <Formik
      innerRef={formRef}
      initialValues={props.initialValues || defaultExtMessageConfig(props.orgName, 'no-reply@nwtoht.ca')}
      validate={(values: IValues) => {
        let errors: Partial<IValues> = {};
        return errors;
      }}
      onSubmit={(values, actions) => {
        DEBUG && ClientLogger.debug('CreatePatient', `onSubmit values`, values);
        save(values);
      }}
    >
      {({ submitForm, isSubmitting, errors, values, setFieldValue, touched, setFieldError, setFieldTouched, dirty }) => {
        DEBUG && ClientLogger.debug('EditOrg', 'render form', { isSubmitting, errors, values, touched, dirty, submitForm });
        return (
          <>
            <DialogTitle sx={dialogClasses.dialogTitle}>
              {dialogTitle}
              <IconButton onClick={props.onClose} size="large">
                <CloseIcon sx={dialogClasses.closeIcon} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Typography variant="h5">Servers</Typography>
              <Box mb={2}>
                <EditMailConfig
                  baseName="mailConfig.testEmailServer"
                  values={values.mailConfig.testEmailServer}
                  title="Test Email Servers"
                  panelId="panel3"
                  openPanel={(panel) => setPanelOpen(panel)}
                  panelOpen={panelOpen}
                />
              </Box>
              <Box mb={2}>
                <EditMailConfig
                  baseName="mailConfig.productionEmailServer"
                  values={values.mailConfig.productionEmailServer}
                  title="Production Email Servers"
                  panelId="panel4"
                  openPanel={(panel) => setPanelOpen(panel)}
                  panelOpen={panelOpen}
                />
              </Box>
              <Box mb={2}>
                <Accordion expanded={panelOpen === 'panel1'} onChange={openPanel('panel1')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant="h6">SMS Configuration</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Field
                      component={TextField}
                      name={`smsConfig.accountSid`}
                      label="Account SID  (Twilio)"
                      placeholder="Enter Account SID"
                      type="text"
                      fullWidth={true}
                      variant="outlined"
                      sx={dialogClasses.inputField}
                    />
                    <Field
                      component={TextField}
                      name={`smsConfig.authToken`}
                      label="Auth Token (Twilio)"
                      placeholder="Enter Auth Token (leave blank for no change)"
                      type="password"
                      fullWidth={true}
                      variant="outlined"
                      sx={dialogClasses.inputField}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
              <Typography variant="h5" paragraph>
                Templates
              </Typography>
              {Object.keys(values.templates).map((messageTemplateKey, index) => {
                // @ts-ignore
                const messageTemplate = values.templates[messageTemplateKey];
                const panelId = `panel_${index}`;
                console.log('panelId', panelId);
                return (
                  <Box mb={2}>
                    <MessageTemplateConfig
                      key={messageTemplateKey}
                      baseName={`templates.${messageTemplateKey}.templateText.en`}
                      title={messageTemplateKey}
                      values={messageTemplate}
                      panelId={panelId}
                      openPanel={(panel) => setPanelOpen(panel)}
                      panelOpen={panelOpen}
                    />
                  </Box>
                );
              })}
              <Accordion expanded={panelOpen === 'panel2'} onChange={openPanel('panel2')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography variant="h5">Master Template</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Field
                    component={TextField}
                    name={`masterTemnplate`}
                    label="Master Template"
                    placeholder=""
                    type="text"
                    multiline
                    fullWidth={true}
                    variant="outlined"
                    sx={dialogClasses.inputField}
                    maxRows={12}
                  />
                </AccordionDetails>
              </Accordion>
            </DialogContent>
            <DialogActions>
              <Button color="primary" disabled={isSubmitting} onClick={() => props.onClose()}>
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={isSubmitting} onClick={() => save(values)}>
                Save
              </Button>
            </DialogActions>
          </>
        );
      }}
    </Formik>
  );
}

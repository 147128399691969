import { LayoutResource } from 'components/layout/LayoutResource';
import { Loading } from 'components/widgets/Loading';
import { OrgType } from 'lib/api/api-types';
import { NEW_AUTH_MESSAGE_ID, refreshTokenFct, tokenHolder, useAuthService } from 'lib/api/use-auth-service';
import { UserContext } from 'lib/auth/user-context';
import { ClientLogger } from 'lib/client-logger';
import { ChangePassword } from 'pages/auth/ChangePassword';
import { ResetPasswordStart } from 'pages/auth/ResetPasswordStart';
import { ServerInfo } from 'pages/auth/ServerInfo';
import { UserInfo } from 'pages/auth/UserInfo';
import { IdTypeList } from 'pages/id-type/IdTypeList';
import { OrgList } from 'pages/org/OrgList';
import { PersonOrgList } from 'pages/person-org/PersonOrgList';
import { DesignationList } from 'verid-ui/src/pages/designation/DesignationList';
import { ResourcePage, RESOURCE_NAMES } from 'pages/resources/ResourcePage';
import { Todo } from 'pages/resources/Todo';
import { TeamList } from 'pages/team/TeamList';
import { VerificationMethodList } from 'pages/verification-method/VerificationMethodList';
import { QueryChecker } from 'QueryChecker';
import React, { useCallback, useContext, useEffect } from 'react';

import { BrowserRouter, Routes as RouterRoutes, Route, useLocation, Navigate } from 'react-router-dom';
import { Login } from './pages/auth/Login';
import { LoginOrgsList } from './pages/auth/LoginOrgsList';
import { Home } from './pages/home/Home';
import { AgreementList } from 'pages/agreements/AgreementList';
import { AgreementCheck } from 'components/auth/AgreementCheck';

export enum ROUTES {
  HOME = '/',
  LOGIN = '/login',
  LOGIN_ORGS_LIST = '/loginOrgsList',
  LOGOUT = '/logout',
  SERVER_INFO = '/serverInfo',
  USER_INFO = '/userInfo',
  HELP = '/help',
  TERMS_OF_USE = '/tou',
  PRIVACY_POLICY = '/pp',
  ABOUT = '/about',
  PROFILE = '/profile',
  JURISDICTION_LIST = '/jurList',
  PROVIDER_ORG_LIST = '/proOrgList',
  APP_LIST = '/appList',
  DESIGNATION_LIST = '/designationList',
  PERSON_ORG_LIST = '/personOrgList',
  TEAM_LIST = '/teamList',
  VERIFICATION_METHOD_LIST = '/verificationMethodList',
  ID_TYPE_LIST = '/idTypeList',
  RESET_PASSWORD_START = '/resetPasswordStart',
  RESET_PASSWORD_FINISH = '/resetPasswordFinish',
  AGREEMENT_LIST = '/agreementList',
}

const DEBUG = false;

function RequireAuth({ children }: { children: JSX.Element }) {
  const userContext = useContext(UserContext);
  let location = useLocation();

  if (!userContext.userState.isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    DEBUG && ClientLogger.debug('RequireAuth:', 'Not logged in', { userContext, location });
    return <Navigate to={ROUTES.LOGIN} />;
  }

  return children;
}
export function Routes() {
  const authService = useAuthService();
  const userContext = useContext(UserContext);
  const onMessageReceivedFromIframe = useCallback((ev: any) => {
    const data = ev.data;
    if (data === NEW_AUTH_MESSAGE_ID) {
      DEBUG && ClientLogger.debug('AuthClient', `onMessageReceivedFromIframe ${JSON.stringify(ev.data)} Calling getMe`);
      authService.getMe();
    }
  }, []);

  useEffect(() => {
    DEBUG && ClientLogger.debug('AuthClient', 'useEffect - installing listener', { tokenHolder });
    window.addEventListener('message', onMessageReceivedFromIframe);
    return () => window.removeEventListener('message', onMessageReceivedFromIframe);
  }, [onMessageReceivedFromIframe]);

  useEffect(() => {
    // attempt to get token from server if not already set for returning users
    if (!tokenHolder.accessToken) {
      refreshTokenFct();
    }
  }, []);

  if (!userContext.userState.ready) {
    return <Loading />;
  }
  return (
    <>
      <AgreementCheck
        logout={() => {
          authService.logout(ROUTES.HOME);
          window.location.reload();
        }}
      />
      <BrowserRouter>
        <QueryChecker />
        <RouterRoutes>
          <Route path={ROUTES.HOME} element={<Home />} />
          <Route path={ROUTES.LOGIN} element={<Login />} />
          <Route path={ROUTES.LOGIN_ORGS_LIST} element={<LoginOrgsList />} />
          <Route path={ROUTES.RESET_PASSWORD_START} element={<ResetPasswordStart />} />
          <Route path={ROUTES.RESET_PASSWORD_FINISH} element={<ChangePassword />} />
          <Route path={ROUTES.USER_INFO} element={<UserInfo />} />
          <Route path={ROUTES.SERVER_INFO} element={<ServerInfo />} />
          <Route path={ROUTES.PROFILE} element={<Todo />} />
          <Route
            path={ROUTES.JURISDICTION_LIST}
            element={
              <RequireAuth>
                <OrgList orgType={OrgType.JURISDICTION} title="Jurisdictions" />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.APP_LIST}
            element={
              <RequireAuth>
                <OrgList orgType={OrgType.APP} title="App Organizations" />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.PROVIDER_ORG_LIST}
            element={
              <RequireAuth>
                <OrgList orgType={OrgType.PROVIDER} title="Provider Organizations" />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.PERSON_ORG_LIST}
            element={
              <RequireAuth>
                <PersonOrgList />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.TEAM_LIST}
            element={
              <RequireAuth>
                <TeamList title="Teams" />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.DESIGNATION_LIST}
            element={
              <RequireAuth>
                <DesignationList />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.VERIFICATION_METHOD_LIST}
            element={
              <RequireAuth>
                <VerificationMethodList />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.ID_TYPE_LIST}
            element={
              <RequireAuth>
                <IdTypeList />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.AGREEMENT_LIST}
            element={
              <AgreementList>
                <IdTypeList />
              </AgreementList>
            }
          />
          <Route
            path={ROUTES.HELP}
            element={
              <LayoutResource>
                <ResourcePage resourceName={RESOURCE_NAMES.HELP} />
              </LayoutResource>
            }
          />
          <Route
            path={ROUTES.PRIVACY_POLICY}
            element={
              <LayoutResource>
                <ResourcePage resourceName={RESOURCE_NAMES.PP} />
              </LayoutResource>
            }
          />
          <Route
            path={ROUTES.TERMS_OF_USE}
            element={
              <LayoutResource>
                <ResourcePage resourceName={RESOURCE_NAMES.TOU} />
              </LayoutResource>
            }
          />
          <Route
            path={ROUTES.ABOUT}
            element={
              <LayoutResource>
                <ResourcePage resourceName={RESOURCE_NAMES.ABOUT} />
              </LayoutResource>
            }
          />
        </RouterRoutes>
      </BrowserRouter>
    </>
  );
}

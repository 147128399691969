import { Box, Button, FormControl, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field, Formik, FormikValues } from 'formik';
import { TextField } from 'formik-mui';
import React, { useContext, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate, Location } from 'react-router-dom';

import { FormContainer } from '../../components/form/FormContainer';
import { ClientLogger } from '../../lib/client-logger';

import { ROUTES } from '../../Routes';
import { tokenHolder, useAuthService } from '../../lib/api/use-auth-service';
import { SEVERITY, ToastDispatchContext, EMAIL_REGEX, Util } from 'ui-lib';

import { theme } from '../../style/theme';

interface IValues {
  email: string;
  password: string;
}

const DEBUG = false;

const useClasses = makeStyles({
  formContainer: {
    margin: '20vh auto 0 auto',
    width: 400,
    maxWidth: '90vw',
    position: 'relative',
    minHeight: '20vh',
    padding: 24,
    [theme.breakpoints.down('sm')]: {
      margin: '4px auto',
      padding: '48px 16px 16px 16px',
    },
  },
  itemHolder: {
    marginBottom: 16,
    width: '100%',
  },
  formField: {
    marginBottom: 12,
  },
  orgSignIn: {
    marginTop: 12,
  },
});

export function Login() {
  const classes = useClasses();
  const navigate = useNavigate();
  const location = useLocation();
  const toastDispatch = useContext(ToastDispatchContext);
  const authApi = useAuthService();
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (values: FormikValues, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
    const debugLocation = 'Login.handleSubmit';
    ClientLogger.debug(debugLocation, 'start', DEBUG);

    try {
      const result = await authApi.login(values.email, values.password);
      if (result) {
        const userId = tokenHolder.jwt?.userId || tokenHolder.jwt?.sub;
        DEBUG && ClientLogger.debug(debugLocation, 'success', { userId, result, tokenHolder });
        if (!userId) {
          toastDispatch({ severity: SEVERITY.ERROR, msg: 'Login did not set user id' });
        } else {
          const me = await authApi.getMe();
        }
        // Check if user was redirected to login - if current location has 'from' in state, send user back where they came from
        const redirectedFrom: Location | undefined = (location.state as { from?: Location })?.from;
        if (!!redirectedFrom) {
          DEBUG && ClientLogger.debug(debugLocation, 'redirecting to', redirectedFrom.pathname);
          navigate(redirectedFrom.pathname, redirectedFrom.state as any);
          return;
        }

        ClientLogger.debug('Login.handleSubmit', 'redirect HOME', DEBUG);
        navigate(ROUTES.HOME);
        return;
      } else {
        toastDispatch({ msg: 'Invalid email or password', severity: SEVERITY.ERROR, autoClose: false });
      }
    } catch (e) {
      ClientLogger.error('Login', 'Error', e);
      // toast
      toastDispatch({ msg: 'Login failed', severity: SEVERITY.ERROR });
      setSubmitting(false);
    }
  };

  return (
    <Grid container flexDirection="column" justifyContent="center">
      <Paper className={classes.formContainer}>
        <Grid container justifyContent="center">
          <Grid item className={classes.itemHolder}>
            <Grid container direction="column" justifyContent="center">
              <Box mt={1} mb={3}>
                <Typography variant="h3">Sign In</Typography>
              </Box>
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                }}
                validate={(values) => {
                  const errors: Partial<IValues> = {};
                  if (!values.email) {
                    errors.email = 'Required';
                  } else if (!EMAIL_REGEX.test(values.email)) {
                    errors.email = 'Invalid email address';
                  }
                  return errors;
                }}
                onSubmit={handleSubmit}
              >
                {({ submitForm, isSubmitting }) => (
                  <FormContainer>
                    <FormControl fullWidth required variant="filled">
                      <Field
                        component={TextField}
                        name="email"
                        type="text"
                        label="Email Address"
                        data-test="input-email"
                        variant="outlined"
                        className={classes.formField}
                      />

                      <Field
                        component={TextField}
                        type={showPassword ? 'text' : 'password'}
                        label="Password"
                        name="password"
                        variant="outlined"
                        data-test="input-password"
                        className={classes.formField}
                        InputProps={{
                          endAdornment: (
                            <Typography onClick={() => (showPassword ? setShowPassword(false) : setShowPassword(true))} variant="caption">
                              {showPassword ? 'Hide' : 'Show'}
                            </Typography>
                          ),
                        }}
                      />
                    </FormControl>
                    {isSubmitting && <LinearProgress />}

                    <Typography variant="caption" color="textSecondary" paragraph>
                      Need Help Signing In? <a href={ROUTES.RESET_PASSWORD_START}>Set or Change your Healix Password</a>
                    </Typography>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                      fullWidth
                      data-test="button-sign-in"
                    >
                      Sign In
                    </Button>

                    <Button
                      className={classes.orgSignIn}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        window.location.href =
                          Util.getEnvVar('REACT_APP_VERID_UI') + '/loginOrgsList' + `?callbackURL=${Util.getEnvVar('REACT_APP_VERID_UI')}`;
                      }}
                      fullWidth
                    >
                      Sign In With SSO
                    </Button>
                  </FormContainer>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

import React, { useContext, useRef, useState, useEffect } from 'react';
import { FetchResult } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Clear';
import { Box, Button, Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Typography } from '@mui/material';
import { EmailOnlyField, IdTypeItem, Password, SEVERITY, ToastDispatchContext, VerificationSubFields } from 'ui-lib';
import { Field, Formik, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import { Util } from 'verid-shared-lib';
import { Org, useOrgService } from 'lib/api/use-org-service';
import { personOrgCreateConnectPersonMutation, personOrgCreateNewPersonMutation, Role, VerificationMethodType } from 'lib/api/api-types';
import { Person, usePersonOrgService } from 'lib/api/use-person-org-serivce';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import { SearchCriteria } from './PersonOrgSearchResult';
import { dialogClasses } from 'style/sharedCssClasses';
import { PersonOrgSearch } from './PersonOrgSearch';
import { VerificationMethodSelector, RoleSelector } from 'ui-lib';
import { getVeridApolloClient } from 'lib/api/apollo-client';

interface IValues {
  title?: string;
  firstName?: string | null;
  middleName?: string;
  lastName?: string | null;
  role?: Role;
  email?: string;
  temporaryPassword?: string;
  attachToPersonId?: string;
  verification?: VerificationSubFields;
  idTypes?: IdTypeItem[];
  systemAccount?: boolean;
}

interface IProps {
  orgId: string;
  onClose: () => void;
}

const DEBUG = true;

export function AddPersonOrg(props: IProps) {
  const personOrgService = usePersonOrgService();
  const errorHandler = useErrorHandler('EditOrg');
  const orgService = useOrgService();
  const [org, setOrg] = useState<Org | undefined>(undefined);
  const formRef = useRef<FormikProps<IValues>>(null);
  const [adding, setAdding] = useState(false);
  const toastDispatch = useContext(ToastDispatchContext);
  const veridApolloClient = getVeridApolloClient();

  useEffect(() => {
    if (props.orgId) {
      orgService
        .org(props.orgId)
        .then((resp) => {
          const { data } = resp;
          const respOrg = data && data.org;
          DEBUG && ClientLogger.debug('EditOrg', 'load org', { resp, respOrg, org });
          if (respOrg) {
            setOrg({ ...respOrg }); // copy to avoid mutating the original and causing an apollo error
          } else {
            errorHandler.handleErrors({ graphQLErrors: resp.errors });
          }
        })
        .catch((err) => {
          errorHandler.handleErrors({ error: err });
        });
    }
  }, [props.orgId]);

  async function save(values: IValues) {
    DEBUG && ClientLogger.debug('AddPersonOrg', 'save', { values });
    const role = values.role;
    if (!role) {
      errorHandler.handleErrors({ error: 'Role is required' });
      return;
    }
    let resp: FetchResult<personOrgCreateNewPersonMutation | personOrgCreateConnectPersonMutation>;
    let id;

    if (org && !Util.checkValidEmailDomain(org.emailDomain, role, values.email)) {
      errorHandler.handleErrors({ error: `Email domain must end with ${org.emailDomain[role]}` });
      return;
    }
    const verif = values.verification;
    if (!verif || !verif.passed) {
      errorHandler.handleErrors({ error: 'Successful verification is required' });
      return;
    } else {
      if (!values.verification?.passed) {
        errorHandler.handleErrors({ error: 'Successful verification is required' });
        return;
      } else {
        const selectedIdTypes = values.idTypes?.filter((idType) => idType.id !== undefined);
        const respCreate = await personOrgService.personOrgCreateNewPerson({
          orgId: props.orgId,
          role,
          email: values.email,
          password: values.temporaryPassword,
          verification: {
            title: values.title,
            firstName: values.firstName,
            middleName: values.middleName,
            lastName: values.lastName,
            methodId: values?.verification.id,
            idTypes:
              selectedIdTypes && selectedIdTypes.length > 0
                ? selectedIdTypes.map((idType) => ({
                    id: idType.id,
                    idNumber: idType.idNumber,
                    idExpiry: idType.idExpiry,
                  }))
                : undefined,
            passed: values?.verification.passed,
          },
          systemAccount: values.systemAccount,
        });

        id = respCreate.data?.personOrgCreateNewPerson?.id;
        resp = respCreate;
        DEBUG && ClientLogger.debug('AddPersonOrg', 'add resp', { resp, id });
      }
    }
    if (id) {
      if (values.attachToPersonId) {
        toastDispatch({
          severity: SEVERITY.SUCCESS,
          msg: `Existing person record for ${values.firstName} ${values.lastName} as added to this organization`,
          autoClose: true,
        });
      } else {
        toastDispatch({
          severity: SEVERITY.SUCCESS,
          msg: `New person record for ${values.firstName} ${values.lastName} as added to this organization`,
          autoClose: true,
        });
      }
      DEBUG && ClientLogger.debug('AddPersonOrg', 'closing');
      props.onClose();
    } else {
      errorHandler.handleErrors({ graphQLErrors: resp.errors });
    }
  }

  DEBUG && ClientLogger.debug('AddPersonOrg', 'render', { props, formRef: JSON.stringify(formRef.current) });
  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={{
          title: '',
          firstName: '',
          middleName: '',
          lastName: '',
          role: Role.USER,
          email: '',
          temporaryPassword: '',
          attachToPersonId: '',
          verification: {
            id: '',
            idTypes: [],
            passed: false,
            verificationMethodType: VerificationMethodType.PERSONAL_KNOWLEDGE,
          },
          idTypes: [],
          systemAccount: false,
        }}
        validate={(values: IValues) => {
          let errors: Partial<IValues> = {};
          return errors;
        }}
        onSubmit={(values, actions) => {
          DEBUG && ClientLogger.debug('CreatePatient', `onSubmit values`, values);
          save(values);
        }}
      >
        {({ submitForm, isSubmitting, errors, values, setFieldValue, touched, setFieldError, setFieldTouched, dirty, handleChange }) => {
          return (
            <>
              {!adding && (
                <>
                  <DialogTitle sx={dialogClasses.dialogTitle}>
                    Search
                    <IconButton onClick={props.onClose} size="large">
                      <CloseIcon sx={dialogClasses.closeIcon} />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <RoleSelector name="role" />
                    <PersonOrgSearch
                      org={org}
                      role={values.role}
                      onClose={function (): void {}}
                      onSelect={function (person: Person, id?: string): void {
                        setFieldValue('attachToPersonId', person.id);
                        DEBUG &&
                          ClientLogger.debug('AddPersonOrg', 'onSelect', {
                            person: person || 'undefined',
                            personId: person.id,
                            id,
                            role: values.role,
                          });
                        save({ attachToPersonId: person.id, role: values.role, firstName: person.firstName, lastName: person.lastName });
                      }}
                      onSearch={function (searchCriteria: SearchCriteria): void {
                        setFieldValue('lastName', searchCriteria.lastName);
                        setFieldValue('firstName', searchCriteria.firstName);
                      }}
                    />
                  </DialogContent>
                </>
              )}
              {adding && (
                <>
                  <DialogTitle sx={dialogClasses.dialogTitle}>
                    Add a Person
                    <IconButton onClick={props.onClose} size="large">
                      <CloseIcon sx={dialogClasses.closeIcon} />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <VerificationMethodSelector
                      baseName="verification"
                      baseNameIdTypes="idTypes"
                      orgId={props.orgId}
                      setFieldValue={setFieldValue}
                      apolloClientVerid={veridApolloClient}
                      readFromVerid
                      style={{
                        inputField: {
                          width: '100%',
                          marginTop: '12px',
                        },
                      }}
                    />
                    <Field
                      component={TextField}
                      name="title"
                      label="Title"
                      placeholder="Enter the tite (Mr. Ms. etc)"
                      type="text"
                      fullWidth
                      variant="outlined"
                      data-test="name-textfield"
                      sx={dialogClasses.inputField}
                    />
                    <Field
                      component={TextField}
                      name="firstName"
                      label="First Name"
                      placeholder="Enter the first name"
                      type="text"
                      fullWidth
                      variant="outlined"
                      data-test="new-patient-middle-name-textfield"
                      sx={dialogClasses.inputField}
                    />
                    <Field
                      component={TextField}
                      name="middleName"
                      label="Middle Name"
                      placeholder="Enter the middle name"
                      type="text"
                      fullWidth
                      variant="outlined"
                      data-test="name-textfield"
                      sx={dialogClasses.inputField}
                    />
                    <Field
                      component={TextField}
                      name="lastName"
                      label="Last Name"
                      placeholder="Enter the last name"
                      type="text"
                      fullWidth
                      variant="outlined"
                      data-test="name-textfield"
                      sx={dialogClasses.inputField}
                    />
                    <RoleSelector name="role" />
                    <EmailOnlyField />
                    <Password name="temporaryPassword" fullWidth={true} />
                    <FormControlLabel
                      control={<Checkbox checked={values.systemAccount} />}
                      label="System Account?"
                      name="systemAccount"
                      onChange={handleChange}
                    />
                  </DialogContent>
                </>
              )}
              <DialogActions>
                {!adding && (
                  <Box mr={1}>
                    <Typography variant="body1">If you can't find the person, Add Person</Typography>
                  </Box>
                )}
                <Button onClick={() => setAdding(!adding)} variant={adding ? 'outlined' : 'contained'} color="primary">
                  {adding ? 'Back to Search' : 'Add Person'}
                </Button>
                {adding && (
                  <Button type="submit" variant="contained" color="primary" onClick={() => save(values)}>
                    Add Person
                  </Button>
                )}
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </>
  );
}

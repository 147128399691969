export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A field whose value is a JSON Web Token (JWT): https://jwt.io/introduction. */
  JWT: any;
};

export type Agreement = {
  __typename: 'Agreement';
  content: Scalars['String'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  latestContent: Scalars['String'];
  latestVersion: Scalars['Int'];
  name: Scalars['String'];
  orgId: Scalars['String'];
  requiresAcceptance: Scalars['Boolean'];
  roles: Maybe<Array<Role>>;
  status: Status;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  versions: Array<AgreementVersion>;
};

export type AgreementAcceptanceConnection = {
  __typename: 'AgreementAcceptanceConnection';
  edges: Maybe<Array<AgreementRequiredEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AgreementAcceptanceCreateInput = {
  agreementVersionId: Scalars['String'];
  status: AgreementStatus;
};

export type AgreementAcceptanceOrder = {
  direction: OrderDirection;
  field: AgreementAcceptanceOrderField;
};

/** Properties by which agreement acceptance connections can be ordered. */
export enum AgreementAcceptanceOrderField {
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

export type AgreementAcceptanceSearchInput = {
  agreementId: Scalars['String'];
  personOrgId: Scalars['String'];
};

export type AgreementConnection = {
  __typename: 'AgreementConnection';
  edges: Maybe<Array<AgreementEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AgreementCreateInput = {
  content: Scalars['String'];
  name: Scalars['String'];
  orgId: Scalars['String'];
  requiresAcceptance: Scalars['Boolean'];
  roles: Array<Role>;
};

export type AgreementEdge = {
  __typename: 'AgreementEdge';
  cursor: Scalars['String'];
  node: Agreement;
};

export type AgreementOrder = {
  direction: OrderDirection;
  field: AgreementOrderField;
};

/** Properties by which agreement connections can be ordered. */
export enum AgreementOrderField {
  createdAt = 'createdAt',
  name = 'name',
  updatedAt = 'updatedAt',
}

export type AgreementRequired = {
  __typename: 'AgreementRequired';
  agreementId: Scalars['String'];
  agreementName: Scalars['String'];
  content: Scalars['String'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  orgId: Scalars['String'];
  orgName: Scalars['String'];
  status: Status;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  version: Scalars['Int'];
  versionId: Scalars['String'];
};

export type AgreementRequiredEdge = {
  __typename: 'AgreementRequiredEdge';
  cursor: Scalars['String'];
  node: AgreementRequired;
};

export type AgreementSearchInput = {
  name?: InputMaybe<Scalars['String']>;
  orgId: Scalars['String'];
  status?: InputMaybe<Status>;
};

/** Status of agreement acceptance. */
export enum AgreementStatus {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export type AgreementUpdateInput = {
  content?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  requiresAcceptance?: InputMaybe<Scalars['Boolean']>;
  roles?: InputMaybe<Array<Role>>;
  saveAsNewVersion: Scalars['Boolean'];
  status?: InputMaybe<Status>;
};

export type AgreementVersion = {
  __typename: 'AgreementVersion';
  content: Scalars['String'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  effectiveDate: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  requiresAcceptance: Scalars['Boolean'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  version: Scalars['Int'];
};

/** The Auth Algorithm. */
export enum AuthAlgorithm {
  HMAC_SHA_256 = 'HMAC_SHA_256',
  HMAC_SHA_384 = 'HMAC_SHA_384',
  HMAC_SHA_512 = 'HMAC_SHA_512',
}

export type AuthApiKeyConnection = {
  __typename: 'AuthApiKeyConnection';
  edges: Maybe<Array<AuthApiKeySummaryEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AuthApiKeyCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  keyType: AuthApiKeyType;
  name: Scalars['String'];
  orgId: Scalars['String'];
  origin: Scalars['String'];
  personOrgId?: InputMaybe<Scalars['String']>;
};

export type AuthApiKeyCreated = {
  __typename: 'AuthApiKeyCreated';
  description: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  expiresAt: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  keyType: AuthApiKeyType;
  name: Scalars['String'];
  origin: Scalars['String'];
  value: Scalars['String'];
};

export type AuthApiKeyOrder = {
  direction: OrderDirection;
  field: AuthApiKeyOrderField;
};

/** Properties by which auth api key connections can be ordered. */
export enum AuthApiKeyOrderField {
  createdAt = 'createdAt',
  enabled = 'enabled',
  name = 'name',
}

export type AuthApiKeyRevoked = {
  __typename: 'AuthApiKeyRevoked';
  description: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type AuthApiKeySearchInput = {
  orgId: Scalars['String'];
};

export type AuthApiKeySummary = {
  __typename: 'AuthApiKeySummary';
  createdAt: Scalars['DateTime'];
  description: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  expiresAt: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  keyType: AuthApiKeyType;
  name: Scalars['String'];
  origin: Scalars['String'];
  personOrg: PersonOrg;
  personOrgId: Scalars['String'];
};

export type AuthApiKeySummaryEdge = {
  __typename: 'AuthApiKeySummaryEdge';
  cursor: Scalars['String'];
  node: AuthApiKeySummary;
};

/** The Auth API Key Type. */
export enum AuthApiKeyType {
  ADMIN = 'ADMIN',
  READONLY = 'READONLY',
}

export type AuthConfig = {
  __typename: 'AuthConfig';
  HMAC_secret: Maybe<Scalars['String']>;
  authAlgorithm: Maybe<AuthAlgorithm>;
  authorizationEndpoint: Maybe<Scalars['String']>;
  buttonName: Maybe<Scalars['String']>;
  clientId: Maybe<Scalars['String']>;
  clientSecret: Maybe<Scalars['String']>;
  cookieName: Maybe<Scalars['String']>;
  cookieType: Maybe<CookieType>;
  identityProviderApiKey: Maybe<Scalars['String']>;
  identityProviderClientId: Maybe<Scalars['String']>;
  identityProviderId: Maybe<Scalars['String']>;
  identityProviderKeyId: Maybe<Scalars['String']>;
  identityProviderName: Maybe<Scalars['String']>;
  identityProviderSecret: Maybe<Scalars['String']>;
  jwtTokenDurationSeconds: Maybe<Scalars['Int']>;
  loginPageSlug: Maybe<Scalars['String']>;
  openIdRoles: Maybe<Array<Scalars['String']>>;
  passwordResetTokenDurationMinutes: Maybe<Scalars['Int']>;
  reconcileEndpoint: Maybe<Scalars['String']>;
  reconcileTokenMode: Maybe<Scalars['Boolean']>;
  refreshTokenDurationMinutes: Maybe<Scalars['Int']>;
  tokenEndpoint: Maybe<Scalars['String']>;
  type: OrgAuthConfigType;
  userInfoEndpoint: Maybe<Scalars['String']>;
};

export type AuthEvent = {
  __typename: 'AuthEvent';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  email: Maybe<Scalars['String']>;
  eventType: AuthEventType;
  id: Scalars['ID'];
  ipAddress: Maybe<Scalars['String']>;
  personOrg: Maybe<PersonOrg>;
  personOrgId: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  testData: Maybe<Scalars['JSON']>;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  userAgent: Maybe<Scalars['String']>;
};

export type AuthEventConnection = {
  __typename: 'AuthEventConnection';
  edges: Maybe<Array<AuthEventEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AuthEventEdge = {
  __typename: 'AuthEventEdge';
  cursor: Scalars['String'];
  node: AuthEvent;
};

export type AuthEventOrder = {
  direction: OrderDirection;
  field: AuthEventOrderField;
};

/** Properties by which auth events connections can be ordered. */
export enum AuthEventOrderField {
  createdAt = 'createdAt',
}

export type AuthEventSearchInput = {
  personOrgId: Scalars['String'];
};

/** The Auth Event Type. */
export enum AuthEventType {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  LOGIN = 'LOGIN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  RESET_PASSWORD_COMPLETE = 'RESET_PASSWORD_COMPLETE',
  RESET_PASSWORD_START = 'RESET_PASSWORD_START',
}

export type ChangePasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

/** The Org Auth Config Cookie Type. */
export enum CookieType {
  SET_COOKIE = 'SET_COOKIE',
  SET_HEADER = 'SET_HEADER',
  SET_URL_PARAMS = 'SET_URL_PARAMS',
}

export type Designation = {
  __typename: 'Designation';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status: Status;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type DesignationConnection = {
  __typename: 'DesignationConnection';
  edges: Maybe<Array<DesignationEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DesignationEdge = {
  __typename: 'DesignationEdge';
  cursor: Scalars['String'];
  node: Designation;
};

export type DesignationInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  status?: InputMaybe<Status>;
};

export type DesignationOrder = {
  direction: OrderDirection;
  field: DesignationOrderField;
};

/** Properties by which person connections can be ordered. */
export enum DesignationOrderField {
  createdAt = 'createdAt',
  description = 'description',
  updatedAt = 'updatedAt',
}

export type DesignationSearchInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
};

export type IdType = {
  __typename: 'IdType';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  description: Maybe<Scalars['String']>;
  expiryDateFormat: Maybe<Scalars['String']>;
  expiryDateLabel: Maybe<Scalars['String']>;
  expiryDatePlaceHolder: Maybe<Scalars['String']>;
  format: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Maybe<Scalars['String']>;
  placeHolder: Maybe<Scalars['String']>;
  status: Status;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  useExpiryDate: Scalars['Boolean'];
};

export type IdTypeConnection = {
  __typename: 'IdTypeConnection';
  edges: Maybe<Array<IdTypeEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type IdTypeEdge = {
  __typename: 'IdTypeEdge';
  cursor: Scalars['String'];
  node: IdType;
};

export type IdTypeInput = {
  description?: InputMaybe<Scalars['String']>;
  expiryDateFormat?: InputMaybe<Scalars['String']>;
  expiryDateLabel?: InputMaybe<Scalars['String']>;
  expiryDatePlaceHolder?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  label?: InputMaybe<Scalars['String']>;
  placeHolder?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  useExpiryDate?: InputMaybe<Scalars['Boolean']>;
};

export type IdTypeOrder = {
  direction: OrderDirection;
  field: IdTypeOrderField;
};

/** Properties by which person connections can be ordered. */
export enum IdTypeOrderField {
  createdAt = 'createdAt',
  description = 'description',
  updatedAt = 'updatedAt',
}

export type IdTypeSearchInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename: 'Mutation';
  agreementAcceptanceCreate: AgreementRequired;
  agreementCreate: Agreement;
  agreementUpdate: Agreement;
  authApiKeyCreate: AuthApiKeyCreated;
  authApiKeyRevoke: AuthApiKeyRevoked;
  changePassword: PersonOrg;
  designationCreate: Designation;
  designationUpdate: Designation;
  generateHMACSecret: Scalars['String'];
  idTypeCreate: IdType;
  idTypeUpdate: IdType;
  logOut: Scalars['Boolean'];
  login: Token;
  orgCreate: Org;
  orgDelete: Org;
  orgOrgCreate: OrgOrg;
  orgOrgDelete: OrgOrg;
  orgUpdate: Org;
  passwordResetFinish: Scalars['Boolean'];
  passwordResetStart: Scalars['Boolean'];
  personDelete: Person;
  personOrgChangeRole: PersonOrgUpsertResponse;
  personOrgCreateConnectPerson: PersonOrg;
  personOrgCreateNewPerson: PersonOrg;
  personOrgDelete: PersonOrg;
  personOrgUpsert: PersonOrgUpsertResponse;
  personUpdate: PersonOrg;
  refreshToken: Token;
  resetData: Scalars['Boolean'];
  teamCreate: Team;
  teamMemberCreate: TeamMember;
  teamMemberUpdate: TeamMember;
  teamUpdate: Team;
  verificationMethodCreate: VerificationMethod;
  verificationMethodUpdate: VerificationMethod;
};

export type MutationagreementAcceptanceCreateArgs = {
  data: AgreementAcceptanceCreateInput;
};

export type MutationagreementCreateArgs = {
  data: AgreementCreateInput;
};

export type MutationagreementUpdateArgs = {
  data: AgreementUpdateInput;
  id: Scalars['String'];
};

export type MutationauthApiKeyCreateArgs = {
  data: AuthApiKeyCreateInput;
};

export type MutationauthApiKeyRevokeArgs = {
  id: Scalars['String'];
};

export type MutationchangePasswordArgs = {
  data: ChangePasswordInput;
  personOrgId: Scalars['String'];
};

export type MutationdesignationCreateArgs = {
  data: DesignationInput;
};

export type MutationdesignationUpdateArgs = {
  data: DesignationInput;
};

export type MutationgenerateHMACSecretArgs = {
  authAlgorithm: Scalars['String'];
  id: Scalars['String'];
};

export type MutationidTypeCreateArgs = {
  data: IdTypeInput;
};

export type MutationidTypeUpdateArgs = {
  data: IdTypeInput;
};

export type MutationloginArgs = {
  data: LoginInput;
};

export type MutationorgCreateArgs = {
  data: OrgCreateInput;
};

export type MutationorgDeleteArgs = {
  id: Scalars['String'];
};

export type MutationorgOrgCreateArgs = {
  data: OrgRelationInput;
};

export type MutationorgOrgDeleteArgs = {
  data: OrgRelationInput;
};

export type MutationorgUpdateArgs = {
  data: OrgUpdateInput;
  id: Scalars['String'];
};

export type MutationpasswordResetFinishArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export type MutationpasswordResetStartArgs = {
  email: Scalars['String'];
};

export type MutationpersonDeleteArgs = {
  id: Scalars['String'];
};

export type MutationpersonOrgChangeRoleArgs = {
  data: PersonOrgChangeRoleInput;
};

export type MutationpersonOrgCreateConnectPersonArgs = {
  data: PersonOrgCreateConnectPersonInput;
};

export type MutationpersonOrgCreateNewPersonArgs = {
  data: PersonOrgCreateNewPersonInput;
};

export type MutationpersonOrgDeleteArgs = {
  id: Scalars['String'];
};

export type MutationpersonOrgUpsertArgs = {
  data: PersonOrgUpsertInput;
};

export type MutationpersonUpdateArgs = {
  data: PersonUpdateInput;
};

export type MutationrefreshTokenArgs = {
  token?: InputMaybe<Scalars['JWT']>;
};

export type MutationresetDataArgs = {
  branch: Scalars['String'];
  comment: Scalars['String'];
};

export type MutationteamCreateArgs = {
  data: TeamCreateInput;
};

export type MutationteamMemberCreateArgs = {
  data: TeamMemberCreateInput;
};

export type MutationteamMemberUpdateArgs = {
  data: TeamMemberUpdateInput;
  id: Scalars['String'];
};

export type MutationteamUpdateArgs = {
  data: TeamUpdateInput;
  id: Scalars['String'];
};

export type MutationverificationMethodCreateArgs = {
  data: VerificationMethodCreateInput;
};

export type MutationverificationMethodUpdateArgs = {
  data: VerificationMethodUpdateInput;
  id: Scalars['String'];
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
  asc = 'asc',
  desc = 'desc',
}

export type Org = {
  __typename: 'Org';
  attributes: Maybe<Scalars['JSON']>;
  authConfig: Maybe<Scalars['JSON']>;
  autoOnboard: Scalars['Boolean'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  description: Maybe<Scalars['String']>;
  emailDomain: Maybe<Scalars['JSON']>;
  extMessageConfig: Maybe<Scalars['JSON']>;
  externalId: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isTest: Scalars['Boolean'];
  name: Scalars['String'];
  status: Status;
  type: OrgType;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

/** The Org Auth Config Type. */
export enum OrgAuthConfigType {
  EXTERNALLY_PROVIDED = 'EXTERNALLY_PROVIDED',
  IDENTITY_PROVIDER = 'IDENTITY_PROVIDER',
  LOCALLY_PROVIDED = 'LOCALLY_PROVIDED',
  OPEN_ID = 'OPEN_ID',
  SAML = 'SAML',
}

export type OrgConnection = {
  __typename: 'OrgConnection';
  edges: Maybe<Array<OrgEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type OrgCreateInput = {
  attributes?: InputMaybe<Scalars['JSON']>;
  authConfig?: InputMaybe<Scalars['JSON']>;
  autoOnboard?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  emailDomain?: InputMaybe<Scalars['JSON']>;
  extMessageConfig?: InputMaybe<Scalars['JSON']>;
  externalId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isTest?: InputMaybe<Scalars['Boolean']>;
  jurisdictionId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  status?: InputMaybe<Status>;
  type: OrgType;
};

export type OrgEdge = {
  __typename: 'OrgEdge';
  cursor: Scalars['String'];
  node: Org;
};

export type OrgOrder = {
  direction: OrderDirection;
  field: OrgOrderField;
};

/** Properties by which org connections can be ordered. */
export enum OrgOrderField {
  createdAt = 'createdAt',
  name = 'name',
  updatedAt = 'updatedAt',
}

export type OrgOrg = {
  __typename: 'OrgOrg';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  org1Id: Scalars['String'];
  org2Id: Scalars['String'];
  relation: OrgRelation;
  status: Status;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

/** The Relationship Type Between Two Organizations */
export enum OrgRelation {
  PROVIDER1_APP2 = 'PROVIDER1_APP2',
  PROVIDER1_JURISDICTION2 = 'PROVIDER1_JURISDICTION2',
}

export type OrgRelationInput = {
  org1Id: Scalars['String'];
  org2Id: Scalars['String'];
  type: OrgRelation;
};

export type OrgRelationSearchInput = {
  org1Id?: InputMaybe<Scalars['String']>;
  org2Id?: InputMaybe<Scalars['String']>;
  type: OrgRelation;
};

export type OrgSearchInput = {
  isTest?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  relation?: InputMaybe<OrgRelationSearchInput>;
  status?: InputMaybe<Status>;
  type?: InputMaybe<OrgType>;
};

/** The Organization Type. */
export enum OrgType {
  APP = 'APP',
  JURISDICTION = 'JURISDICTION',
  PROVIDER = 'PROVIDER',
}

export type OrgUpdateInput = {
  attributes?: InputMaybe<Scalars['JSON']>;
  authConfig?: InputMaybe<Scalars['JSON']>;
  autoOnboard?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  emailDomain?: InputMaybe<Scalars['JSON']>;
  extMessageConfig?: InputMaybe<Scalars['JSON']>;
  externalId?: InputMaybe<Scalars['String']>;
  isTest?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
};

export type PageInfo = {
  __typename: 'PageInfo';
  endCursor: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Maybe<Scalars['String']>;
};

export type Person = {
  __typename: 'Person';
  attributes: Maybe<Scalars['JSON']>;
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  currentOrg: Maybe<Scalars['String']>;
  dateOfBirth: Maybe<Scalars['DateTime']>;
  firstName: Maybe<Scalars['String']>;
  gender: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isTest: Scalars['Boolean'];
  lastName: Maybe<Scalars['String']>;
  middleName: Maybe<Scalars['String']>;
  personOrgs: Array<PersonOrg>;
  status: Status;
  systemAccount: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type PersonConnection = {
  __typename: 'PersonConnection';
  edges: Maybe<Array<PersonEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PersonEdge = {
  __typename: 'PersonEdge';
  cursor: Scalars['String'];
  node: Person;
};

export type PersonOrder = {
  direction: OrderDirection;
  field: PersonOrderField;
};

/** Properties by which person connections can be ordered. */
export enum PersonOrderField {
  createdAt = 'createdAt',
  firstName = 'firstName',
  lastName = 'lastName',
  updatedAt = 'updatedAt',
}

export type PersonOrg = {
  __typename: 'PersonOrg';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  email: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isTest: Scalars['Boolean'];
  org: Org;
  orgId: Scalars['String'];
  person: Person;
  personId: Scalars['String'];
  phone: Maybe<Scalars['String']>;
  role: Role;
  status: Status;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type PersonOrgChangeRoleInput = {
  id: Scalars['String'];
  orgId: Scalars['String'];
  role?: InputMaybe<Role>;
  roleId?: InputMaybe<Scalars['String']>;
};

export type PersonOrgConnection = {
  __typename: 'PersonOrgConnection';
  edges: Maybe<Array<PersonOrgEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PersonOrgCreateConnectPersonInput = {
  email?: InputMaybe<Scalars['String']>;
  existingPersonId: Scalars['String'];
  orgId: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  role: Role;
};

export type PersonOrgCreateNewPersonInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  orgId: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  role: Role;
  systemAccount?: InputMaybe<Scalars['Boolean']>;
  verification: PersonVerificationInput;
};

export type PersonOrgEdge = {
  __typename: 'PersonOrgEdge';
  cursor: Scalars['String'];
  node: PersonOrg;
};

export type PersonOrgOrder = {
  direction: OrderDirection;
  field: PersonOrgOrderField;
};

/** Properties by which person org connections can be ordered. */
export enum PersonOrgOrderField {
  createdAt = 'createdAt',
  firstName = 'firstName',
  lastName = 'lastName',
  updatedAt = 'updatedAt',
}

export type PersonOrgSearchInput = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  excludeIds?: InputMaybe<Array<Scalars['String']>>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  isTest?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  systemAccount?: InputMaybe<Scalars['Boolean']>;
};

export type PersonOrgUpsertInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  orgId: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Role>;
  roleId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  title?: InputMaybe<Scalars['String']>;
  verification: PersonVerificationInput;
};

export type PersonOrgUpsertResponse = {
  __typename: 'PersonOrgUpsertResponse';
  created: Maybe<Scalars['Boolean']>;
  entity: Maybe<PersonOrg>;
  success: Maybe<Scalars['Boolean']>;
  updated: Maybe<Scalars['Boolean']>;
};

export type PersonSearchInput = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isTest?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
};

export type PersonUpdateInput = {
  personOrgId: Scalars['String'];
  verification: PersonVerificationInput;
};

export type PersonVerification = {
  __typename: 'PersonVerification';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  createdBy: PersonOrg;
  dateOfBirth: Maybe<Scalars['DateTime']>;
  firstName: Maybe<Scalars['String']>;
  gender: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  idExpiry: Maybe<Scalars['String']>;
  idNumber: Maybe<Scalars['String']>;
  idTypeId: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  method: VerificationMethod;
  methodId: Scalars['String'];
  middleName: Maybe<Scalars['String']>;
  person: Person;
  personId: Scalars['String'];
  status: VerificationStatus;
  title: Maybe<Scalars['String']>;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type PersonVerificationIdType = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  idExpiry?: InputMaybe<Scalars['String']>;
  idNumber?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type PersonVerificationInput = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  idTypes?: InputMaybe<Array<PersonVerificationIdType>>;
  isTest?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  methodId: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  passed?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  verificationData?: InputMaybe<Scalars['JSON']>;
};

export type Query = {
  __typename: 'Query';
  agreement: Agreement;
  agreementAcceptances: AgreementAcceptanceConnection;
  agreements: AgreementConnection;
  agreementsRequired: Array<AgreementRequired>;
  authApiKeysForOrg: AuthApiKeyConnection;
  authEvents: AuthEventConnection;
  designation: Designation;
  designations: DesignationConnection;
  getAllOrgs: Array<Org>;
  hello: Scalars['String'];
  idType: IdType;
  idTypes: IdTypeConnection;
  me: Person;
  org: Org;
  orgs: OrgConnection;
  personOrg: PersonOrg;
  personOrgs: PersonOrgConnection;
  personVerifications: Array<PersonVerification>;
  persons: PersonConnection;
  serverInfo: ServerInfo;
  team: Team;
  teamMember: TeamMember;
  teamMembers: TeamMemberConnection;
  teams: TeamConnection;
  verificationMethod: VerificationMethod;
  verificationMethods: VerificationMethodConnection;
};

export type QueryagreementArgs = {
  id: Scalars['String'];
};

export type QueryagreementAcceptancesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AgreementAcceptanceOrder>;
  query: AgreementAcceptanceSearchInput;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryagreementsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AgreementOrder>;
  query: AgreementSearchInput;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryauthApiKeysForOrgArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AuthApiKeyOrder>;
  query?: InputMaybe<AuthApiKeySearchInput>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryauthEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AuthEventOrder>;
  query?: InputMaybe<AuthEventSearchInput>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QuerydesignationArgs = {
  id: Scalars['String'];
};

export type QuerydesignationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DesignationOrder>;
  query?: InputMaybe<DesignationSearchInput>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QuerygetAllOrgsArgs = {
  data: OrgType;
};

export type QueryhelloArgs = {
  name: Scalars['String'];
};

export type QueryidTypeArgs = {
  id: Scalars['String'];
};

export type QueryidTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<IdTypeOrder>;
  query?: InputMaybe<IdTypeSearchInput>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryorgArgs = {
  id: Scalars['String'];
};

export type QueryorgsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrgOrder>;
  query?: InputMaybe<OrgSearchInput>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QuerypersonOrgArgs = {
  id: Scalars['String'];
};

export type QuerypersonOrgsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PersonOrgOrder>;
  query: PersonOrgSearchInput;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QuerypersonVerificationsArgs = {
  id: Scalars['String'];
  orgId: Scalars['String'];
};

export type QuerypersonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PersonOrder>;
  query: PersonSearchInput;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryteamArgs = {
  id: Scalars['String'];
};

export type QueryteamMemberArgs = {
  id: Scalars['String'];
};

export type QueryteamMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TeamMemberOrder>;
  query: TeamMemberSearchInput;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryteamsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TeamOrder>;
  query: TeamSearchInput;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryverificationMethodArgs = {
  id: Scalars['String'];
};

export type QueryverificationMethodsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<VerificationMethodOrder>;
  query: VerificationMethodSearchInput;
  skip?: InputMaybe<Scalars['Int']>;
};

/** User role */
export enum Role {
  ADMIN = 'ADMIN',
  STAFF = 'STAFF',
  USER = 'USER',
}

export type ServerInfo = {
  __typename: 'ServerInfo';
  branch: Scalars['String'];
  buildDate: Scalars['String'];
  buildId: Scalars['String'];
  commit: Scalars['String'];
  dbConnectTest: Scalars['Boolean'];
  reactAppAddress: Scalars['String'];
};

/** The records Status (ACTIVE or DELETED). */
export enum Status {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type Team = {
  __typename: 'Team';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  orgId: Scalars['String'];
  status: Status;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type TeamConnection = {
  __typename: 'TeamConnection';
  edges: Maybe<Array<TeamEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TeamCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  orgId: Scalars['String'];
  status?: InputMaybe<Status>;
};

export type TeamEdge = {
  __typename: 'TeamEdge';
  cursor: Scalars['String'];
  node: Team;
};

export type TeamMember = {
  __typename: 'TeamMember';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  firstName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName: Maybe<Scalars['String']>;
  middleName: Maybe<Scalars['String']>;
  orgName: Maybe<Scalars['String']>;
  role: TeamRole;
  status: Status;
  teamId: Scalars['String'];
  title: Maybe<Scalars['String']>;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type TeamMemberConnection = {
  __typename: 'TeamMemberConnection';
  edges: Maybe<Array<TeamMemberEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TeamMemberCreateInput = {
  personOrgId: Scalars['String'];
  role: TeamRole;
  status?: InputMaybe<Status>;
  teamId: Scalars['String'];
};

export type TeamMemberEdge = {
  __typename: 'TeamMemberEdge';
  cursor: Scalars['String'];
  node: TeamMember;
};

export type TeamMemberOrder = {
  direction: OrderDirection;
  field: TeamMemberOrderField;
};

/** Properties by which team member connections can be ordered. */
export enum TeamMemberOrderField {
  createdAt = 'createdAt',
  lastName = 'lastName',
  updatedAt = 'updatedAt',
}

export type TeamMemberSearchInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  teamId: Scalars['String'];
};

export type TeamMemberUpdateInput = {
  role: TeamRole;
  status: Status;
};

export type TeamOrder = {
  direction: OrderDirection;
  field: TeamOrderField;
};

/** Properties by which team connections can be ordered. */
export enum TeamOrderField {
  createdAt = 'createdAt',
  name = 'name',
  updatedAt = 'updatedAt',
}

/** Team role */
export enum TeamRole {
  ADMIN = 'ADMIN',
  STAFF = 'STAFF',
}

export type TeamSearchInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orgId: Scalars['String'];
  status?: InputMaybe<Status>;
};

export type TeamUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  status?: InputMaybe<Status>;
};

export type Token = {
  __typename: 'Token';
  /** JWT access token */
  accessToken: Scalars['JWT'];
  /**
   * JWT refresh token
   * @deprecated Token should be passed as http only cookie. It remains for testing purposes
   */
  refreshToken: Maybe<Scalars['JWT']>;
};

export type VerificationMethod = {
  __typename: 'VerificationMethod';
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  idTypes: Array<IdType>;
  instructions: Maybe<Scalars['String']>;
  name: Scalars['String'];
  openIdConfig: Maybe<Scalars['String']>;
  org: Maybe<Org>;
  orgId: Scalars['String'];
  requiresPicture: Scalars['Boolean'];
  status: Status;
  type: VerificationMethodType;
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type VerificationMethodConnection = {
  __typename: 'VerificationMethodConnection';
  edges: Maybe<Array<VerificationMethodEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type VerificationMethodCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  idTypeIds?: InputMaybe<Array<Scalars['String']>>;
  instructions?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  openIdConfig?: InputMaybe<Scalars['String']>;
  orgId: Scalars['String'];
  requiresPicture?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Status>;
  type: VerificationMethodType;
};

export type VerificationMethodEdge = {
  __typename: 'VerificationMethodEdge';
  cursor: Scalars['String'];
  node: VerificationMethod;
};

export type VerificationMethodOrder = {
  direction: OrderDirection;
  field: VerificationMethodOrderField;
};

/** Properties by which verification methods connections can be ordered. */
export enum VerificationMethodOrderField {
  createdAt = 'createdAt',
  name = 'name',
  updatedAt = 'updatedAt',
}

export type VerificationMethodSearchInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orgId: Scalars['String'];
  status?: InputMaybe<Status>;
  type?: InputMaybe<VerificationMethodType>;
};

/** The Verification Method Type. */
export enum VerificationMethodType {
  MANUAL = 'MANUAL',
  OPEN_ID = 'OPEN_ID',
  PERSONAL_KNOWLEDGE = 'PERSONAL_KNOWLEDGE',
  SELF_DECLARED = 'SELF_DECLARED',
  SELF_SOVEREIGN_IDENTITY = 'SELF_SOVEREIGN_IDENTITY',
}

export type VerificationMethodUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  idTypeIds?: InputMaybe<Array<Scalars['String']>>;
  instructions?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  openIdConfig?: InputMaybe<Scalars['String']>;
  requiresPicture: Scalars['Boolean'];
  status?: InputMaybe<Status>;
};

/** The Verification Status. */
export enum VerificationStatus {
  PENDING = 'PENDING',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  VERIFIED = 'VERIFIED',
}

export type verificationMethodsQueryVariables = Exact<{
  query: VerificationMethodSearchInput;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<VerificationMethodOrder>;
}>;

export type verificationMethodsQuery = {
  verificationMethods: {
    __typename: 'VerificationMethodConnection';
    totalCount: number;
    edges: Array<{
      __typename: 'VerificationMethodEdge';
      node: {
        __typename: 'VerificationMethod';
        id: string;
        createdAt: any;
        updatedAt: any;
        name: string;
        description: string | null;
        orgId: string;
        type: VerificationMethodType;
        status: Status;
        requiresPicture: boolean;
        instructions: string | null;
        idTypes: Array<{
          __typename: 'IdType';
          id: string;
          description: string | null;
          label: string | null;
          placeHolder: string | null;
          format: string | null;
          useExpiryDate: boolean;
          expiryDateFormat: string | null;
          expiryDateLabel: string | null;
          expiryDatePlaceHolder: string | null;
        }>;
      };
    }> | null;
  };
};

import { ApolloClient, ApolloQueryResult, gql } from '@apollo/client';
import { verificationMethodsQuery, verificationMethodsQueryVariables } from './verid-api-types';

const DEBUG = true;

export function useVeridService(apolloClient: ApolloClient<any>) {
  return {
    async getVerificationMethods(data: verificationMethodsQueryVariables): Promise<ApolloQueryResult<verificationMethodsQuery>> {
      return apolloClient.query({
        query: gql`
          query verificationMethods(
            $query: VerificationMethodSearchInput!
            $after: String
            $before: String
            $first: Int
            $last: Int
            $skip: Int
            $orderBy: VerificationMethodOrder
          ) {
            verificationMethods(query: $query, after: $after, before: $before, first: $first, last: $last, skip: $skip, orderBy: $orderBy) {
              edges {
                node {
                  id
                  createdAt
                  updatedAt
                  name
                  description
                  orgId
                  type
                  status
                  requiresPicture
                  instructions
                  idTypes {
                    id
                    description
                    label
                    placeHolder
                    format
                    useExpiryDate
                    expiryDateFormat
                    expiryDateLabel
                    expiryDatePlaceHolder
                  }
                }
              }
              totalCount
            }
          }
        `,
        variables: {
          query: data.query,
          after: data.after,
          before: data.before,
          first: data.first,
          last: data.last,
          skip: data.skip,
          orderBy: data.orderBy,
        },
      });
    },
  };
}

import { Box, Grid, Switch, TextField, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';
import * as React from 'react';
import { ApolloClient } from '@apollo/client';
import { useVeridService } from './lib/verid-api/use-verid-service';
import { IdType, VerificationMethodType } from './lib/verid-api/verid-api-types';
import { Selector } from './form';
import { IdName } from './util';
import _ from 'lodash';

interface IProps {
  baseName: string;
  baseNameIdTypes: string;
  orgId: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  apolloClientVerid: ApolloClient<any>;
  style: VerificationMethodSelectorStyle;
  fullWidth?: boolean;
  readFromVerid?: boolean;
}

interface VerificationMethodSelectorStyle {
  inputField: any;
}

interface VerificationMethodItem extends IdName {
  type: VerificationMethodType;
  instructions?: string | null;
  idTypes?: IdTypeItem[];
  passed?: boolean;
}

export interface IdTypeItem extends IdName {
  description?: string;
  expiryDateFormat?: string;
  expiryDateLabel?: string;
  expiryDatePlaceHolder?: string;
  format?: string;
  label?: string;
  placeHolder?: string;
  useExpiryDate: boolean;
  dateOfBirth?: string;
  firstName?: string;
  gender?: string;
  id: string;
  idExpiry?: string;
  idNumber?: string;
  lastName?: string;
  middleName?: string;
  title?: string;
}

export interface VerificationSubFields {
  id: string;
  verificationMethodType: VerificationMethodType;
  idTypes?: IdTypeItem[];
  passed: boolean;
}

export function VerificationMethodSelector(props: IProps) {
  const { baseName, baseNameIdTypes, setFieldValue, apolloClientVerid, orgId, style, fullWidth, readFromVerid } = props;
  const [verificationMethods, setVerificationMethods] = React.useState<VerificationMethodItem[]>([]);
  const [currentVerificationMethod, setCurrentVerificationMethod] = React.useState<VerificationMethodItem | null>(null);
  const [currentIdTypes, setCurrentIdTypes] = React.useState<IdTypeItem[]>([]);

  const veridService = useVeridService(apolloClientVerid);

  React.useEffect(() => {
    if (readFromVerid === true) {
      veridService
        .getVerificationMethods({
          query: {
            orgId: orgId,
          },
        })
        .then((response) => {
          const edges = response.data?.verificationMethods?.edges;
          if (edges) {
            const methods: VerificationMethodItem[] = edges.map((edge) => {
              const verification = edge.node;
              const idTypes: IdTypeItem[] = verification.idTypes.map((idType) => {
                return {
                  id: idType.id,
                  name: idType.id,
                  description: idType.description || '',
                  label: idType.label || `${idType?.id} ID`,
                  placeHolder: idType.placeHolder || `Enter ${idType?.id} ID`,
                  format: idType.format || '',
                  useExpiryDate: idType.useExpiryDate || false,
                  expiryDateFormat: idType.expiryDateFormat || ``,
                  expiryDateLabel: idType.expiryDateLabel || `${idType?.id} Expiry Date`,
                  expiryDatePlaceHolder: idType.expiryDatePlaceHolder || `Enter ${idType?.id} Expiry Date`,
                  idNumber: '',
                  idExpiry: '',
                };
              });
              return {
                id: verification.id,
                name: verification.name,
                type: verification.type,
                instructions: verification.instructions,
                idTypes: idTypes,
              };
            });
            setVerificationMethods(methods);
          }
        });
    }
  }, [orgId]);

  function handleChangeVerificationMethod(value: string) {
    const selected = verificationMethods.find((c) => c.id === value);
    if (selected) {
      setCurrentVerificationMethod(selected);
      setCurrentIdTypes([]);
      setFieldValue(`${baseName}.verificationMethodType`, selected.type);
      setFieldValue(`${baseName}.instructions`, selected.instructions);
      setFieldValue(`${baseName}.idTypes`, selected.idTypes || []);
      setFieldValue(`${baseName}.passed`, selected.type !== VerificationMethodType.MANUAL);
      setFieldValue(`${baseNameIdTypes}`, []);
    }
  }

  function handleChangeIdType(values: string) {
    if (currentVerificationMethod && currentVerificationMethod.idTypes) {
      const selectedValues = JSON.parse(JSON.stringify(values));

      let selectedChoices: IdTypeItem[] = [];

      selectedValues.forEach((value: string) => {
        // If already selected,
        let currentIdType = currentIdTypes.find((c) => c.id === value);
        if (currentIdType) {
          selectedChoices.push(currentIdType);
        }
        // If not already selected, add
        else {
          let choice = currentVerificationMethod?.idTypes?.find((c) => c.id === value);
          if (choice) {
            selectedChoices.push(choice);
          }
        }
      });

      setCurrentIdTypes([...selectedChoices]);
      setFieldValue(baseNameIdTypes, [...selectedChoices]);
    }
  }

  return (
    <>
      <Selector
        name={`${baseName}.id`}
        items={verificationMethods}
        label="Verification Method"
        onChange={(key) => {
          handleChangeVerificationMethod(key);
        }}
      />
      {currentVerificationMethod && (
        <Box key={currentVerificationMethod.id} width={fullWidth ? '100%' : 'inherit'}>
          {currentVerificationMethod?.type === 'MANUAL' && (
            <>
              <Box m={1}>
                <Typography variant="h6">Instructions for {currentVerificationMethod.name}</Typography>
                <Typography variant="body1" dangerouslySetInnerHTML={{ __html: currentVerificationMethod?.instructions || '' }} />
              </Box>
              {currentVerificationMethod.idTypes && currentVerificationMethod.idTypes.length > 0 && (
                <>
                  <Selector
                    selectMultiple
                    name={`${baseName}.idTypes`}
                    items={currentVerificationMethod.idTypes}
                    label="Id Types"
                    onChange={(key) => {
                      handleChangeIdType(key);
                    }}
                  />
                </>
              )}
              {currentIdTypes.map((currentIdType, index) => {
                return (
                  <Box key={currentIdType.id} m={2}>
                    <Typography variant="h6">Fill info for {currentIdType.id}</Typography>
                    <Field
                      component={TextField}
                      name={`${baseNameIdTypes}[${index}].idNumber`}
                      label={currentIdType.label}
                      placeholder={currentIdType.placeHolder}
                      type="text"
                      fullWidth
                      variant="outlined"
                      sx={style.inputField}
                      onChange={(e: any) => {
                        props.setFieldValue(`${baseNameIdTypes}[${index}].idNumber`, e.target.value);
                      }}
                    />
                    {currentIdType.useExpiryDate && (
                      <Field
                        component={TextField}
                        name={`${baseNameIdTypes}[${index}].idExpiry`}
                        label={currentIdType.expiryDateLabel}
                        placeholder={currentIdType.expiryDatePlaceHolder}
                        type="text"
                        fullWidth
                        variant="outlined"
                        sx={style.inputField}
                        onChange={(e: any) => {
                          props.setFieldValue(`${baseNameIdTypes}[${index}].idExpiry`, e.target.value);
                        }}
                      />
                    )}
                  </Box>
                );
              })}
            </>
          )}
          {currentVerificationMethod?.type === VerificationMethodType.MANUAL && (
            <Grid container direction="row">
              <Grid>
                <Field name={`${baseName}.passed`}>
                  {(formikProps: FieldProps) => {
                    const { form } = formikProps;
                    return (
                      <Switch
                        checked={_.get(form.values, `${baseName}.passed`)}
                        onChange={(e) => {
                          form.setFieldValue(`${baseName}.passed`, e.target.checked);
                        }}
                        color="primary"
                        name={`${baseName}.passed`}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    );
                  }}
                </Field>
              </Grid>
              <Grid marginTop={1}>
                <Typography>Check if the person has been verified</Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
    </>
  );
}

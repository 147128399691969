import { MenuItem, Select, Input, FormControl, InputLabel } from '@mui/material';
import { Field, FieldProps } from 'formik';
import * as React from 'react';

import { IdName } from '../util';

interface IProps {
  items: IdName[];
  name: string;
  label: string;
  disabled?: boolean;
  onChange?: (key: string) => void;
  selectMultiple?: boolean;
  dataTestAddButton?: string;
}

const DEBUG = false;

export const Selector = (props: IProps) => {
  const { items, label, name, dataTestAddButton, selectMultiple, disabled } = props;
  DEBUG && console.debug('Selector', 'render', props);
  return (
    <Field name={name}>
      {(formikProps: FieldProps) => {
        DEBUG && console.debug('Selector', 'render field', { formikProps });
        return (
          <FormControl variant="standard" margin="normal" fullWidth>
            <InputLabel htmlFor={label}>{label}</InputLabel>
            <Select
              disabled={disabled}
              fullWidth
              multiple={selectMultiple}
              input={<Input data-test={dataTestAddButton} id={label} />}
              {...formikProps.field}
              onChange={(e, v) => {
                DEBUG && console.debug('Selector', 'change', { e, v });
                const value = e.target.value as string;
                if (props.onChange) {
                  props.onChange(value);
                }
                formikProps.form.setFieldValue(name, value);
              }}
            >
              {items.map((item: any, i) => (
                <MenuItem
                  data-test={`${dataTestAddButton}-${
                    typeof item.id === 'string'
                      ? item.id.toLowerCase()
                      : () => {
                          console.error('Selector', 'item.id is not a string', { item });
                          return '';
                        }
                  }-option`}
                  key={item.id}
                  value={item.id}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }}
    </Field>
  );
};

import { theme } from './theme';

export const dialogClasses = {
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputField: {
    width: '100%',
    marginTop: '12px',
  },
  closeIcon: {
    color: '#000',
  },
  infoIcon: {
    color: '#06c',
  },
  btnSpacing: {
    marginTop: '12px',
  },
};

export const tableClasses = {
  tableHolder: {
    width: '100%',
  },
};

export const containerClasses = {
  formHolder: {
    position: 'relative',
  },
  formContainer: {
    margin: '0 auto 64px auto',
    width: 400,
    maxWidth: '90vw',
    position: 'relative',
    minHeight: '40vh',
    padding: 24,
    sm: {
      margin: '4px auto',
      padding: '48px 16px 16px 16px',
    },
  },
  itemHolder: {
    marginBottom: 16,
    width: '100%',
  },
};

export const formClasses = {
  formError: {
    color: theme.palette.error.main,
  },
};

export const authConfigClasses = {
  secretField: {
    display: 'flex',
    alignItems: 'end',
  },
  generateButton: {
    borderRadius: 2,
    padding: '0 35px',
    maxWidth: '50px',
    maxHeight: '50px',
    minWidth: '50px',
    minHeight: '50px',
  },
};

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { OrgType } from 'lib/api/api-types';
import { AuthConfigUtil } from 'lib/TemplateUtil';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Clear';
import { dialogClasses } from '../../style/sharedCssClasses';
import { SEVERITY, ToastDispatchContext } from 'ui-lib';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
import React, { useState, useEffect, useContext } from 'react';
import { OrgAuthConfig, OrgAuthConfigType } from 'verid-shared-lib';
import { useOrgService } from 'lib/api/use-org-service';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import { AuthConfigModal } from './AuthConfigModal';

interface IProps {
  editingId: string; // if editing an existing org, this is the id. Otherwise we're creating a new one
  orgType: OrgType;
  onClose: () => void;
  initialValues: OrgAuthConfig[];
}

const DEBUG = false;

const DefaultOrgAuthConfig: OrgAuthConfig = {
  authName: '',
  veridRoles: [],
  authConfig: null,
};

export const AuthConfigList = (props: IProps) => {
  const [rowsSelected, setRowsSelected] = useState<number[]>([]);
  const [orgAuthConfigs, setOrgAuthConfigs] = useState<OrgAuthConfig[]>([]);
  const [editingOrgAuthConfig, setEditingOrgAuthConfig] = useState(false);
  const [removeOrgAuthConfig, setRemoveOrgAuthConfig] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState<number | null>(null);
  const orgService = useOrgService();
  const errorHandler = useErrorHandler('EditOrg');
  const toastDispatch = useContext(ToastDispatchContext);

  useEffect(() => {
    const values = props?.initialValues ? [...props.initialValues] : [];
    setOrgAuthConfigs(values);
  }, []);

  const scenarioDataTableOptions: MUIDataTableOptions = {
    pagination: false,
    download: false,
    print: false,
    filter: false,
    search: false,
    viewColumns: false,
    sort: true,
    selectableRows: 'none',
    rowsSelected,
    textLabels: {
      body: {
        noMatch: 'No Mapping Found',
      },
    },
  };

  const columns: MUIDataTableColumnDef[] = [
    { name: 'authName', label: 'Auth Name' },
    { name: 'veridRoles', label: 'Verid Roles' },
    { name: 'type', label: 'Type' },
    { name: 'openIdRoles', label: 'OpenID Roles' },
    {
      name: 'edit',
      label: 'Edit',
      options: {
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          const { rowIndex } = tableMeta;
          return (
            <Box display="flex" justifyContent="space-evenly" alignItems="center">
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setSelectedConfig(rowIndex);
                  setEditingOrgAuthConfig(true);
                }}
              >
                Edit
              </Button>
              <IconButton
                onClick={() => {
                  setSelectedConfig(rowIndex);
                  setRemoveOrgAuthConfig(true);
                }}
                color="primary"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          );
        },
        customHeadRender: () => <td key="edit"></td>,
      },
    },
  ];

  const data: any = orgAuthConfigs.map((list: any, index: number) => ({
    authName: list.authName,
    veridRoles: AuthConfigUtil.convertArrayToString(list.veridRoles),
    type: list.authConfig.type,
    openIdRoles: AuthConfigUtil.convertArrayToString(list.authConfig.openIdRoles || []),
  }));

  function validationFields(value) {
    const isExistingAuthName = orgAuthConfigs.some((orgAuthConfig, index) => {
      return selectedConfig != index && orgAuthConfig.authName.toLowerCase() === value.authName.toLowerCase();
    });
    if (isExistingAuthName) {
      return 'Auth Name must be unique';
    }
    if (!value.veridRoles || !value.veridRoles.length) {
      return 'Verid roles must be fill up';
    }
    const isExistingRole = orgAuthConfigs.some((orgAuthConfig, index) => {
      return selectedConfig != index && value.veridRoles.some((role: string) => orgAuthConfig.veridRoles.includes(role));
    });
    if (isExistingRole) {
      return 'Provided role is already existed';
    }
    if (!value.authConfig) {
      return 'Auth config must be fill up';
    }
    return undefined;
  }

  async function onAddConfig(value: any, isUpdateConfig: boolean) {
    const error = validationFields(value);
    if (error) {
      toastDispatch({ severity: SEVERITY.ERROR, msg: error });
      return;
    }
    let newOrgAuthConfig: OrgAuthConfig = { ...value };
    if (value.authConfig && value.authConfig.type === OrgAuthConfigType.OPEN_ID) {
      const { openIdRoles } = value.authConfig;
      newOrgAuthConfig = {
        ...value,
        authConfig: {
          ...value.authConfig,
          openIdRoles: AuthConfigUtil.convertStringToArray(openIdRoles),
        },
      };
    }
    if (!!isUpdateConfig) {
      const newOrgAuthConfigs = orgAuthConfigs.map((orgAuthConfig, index) => {
        if (index === selectedConfig) {
          return newOrgAuthConfig;
        }
        return orgAuthConfig;
      });
      setOrgAuthConfigs(newOrgAuthConfigs);
    } else {
      setOrgAuthConfigs([...orgAuthConfigs, newOrgAuthConfig]);
    }
    onClearOrgAuthConfig();
  }

  function onClearOrgAuthConfig() {
    setSelectedConfig(null);
    if (editingOrgAuthConfig) setEditingOrgAuthConfig(false);
    if (removeOrgAuthConfig) setRemoveOrgAuthConfig(false);
  }

  function onRemoveConfig() {
    //Remove config from array of orgAC through authName
    if (selectedConfig === null) {
      toastDispatch({ severity: SEVERITY.ERROR, msg: `Must be select any config` });
      return;
    }
    const removeConfig: OrgAuthConfig = orgAuthConfigs[selectedConfig];
    const newOrgAuthConfigs = orgAuthConfigs.filter((orgAuthConfig) => {
      return orgAuthConfig.authName != removeConfig.authName;
    });
    setOrgAuthConfigs(newOrgAuthConfigs);
    onClearOrgAuthConfig();
  }

  async function onSubmit() {
    //Remove empty string secret in the authConfig object
    orgAuthConfigs.forEach((orgAuthConfig: OrgAuthConfig) => {
      const { authConfig } = orgAuthConfig;
      if (authConfig) {
        if (authConfig.hasOwnProperty('HMAC_secret') && !authConfig.HMAC_secret) delete authConfig.HMAC_secret;
        if (authConfig.hasOwnProperty('clientSecret') && !authConfig.clientSecret) delete authConfig.clientSecret;
        if (authConfig.hasOwnProperty('identityProviderSecret') && !authConfig.identityProviderSecret) {
          delete authConfig.identityProviderSecret;
        }
      }
    });
    const updatedResponse = await orgService.orgUpdate(props.editingId, { authConfig: [...orgAuthConfigs] });
    DEBUG && ClientLogger.debug('EditOrg', 'update org', { resp: updatedResponse });
    if (!updatedResponse || !updatedResponse.data || updatedResponse.errors) {
      errorHandler.handleErrors({ graphQLErrors: updatedResponse.errors });
      return;
    }
    const { id } = updatedResponse.data.orgUpdate;
    if (id) {
      toastDispatch({ severity: SEVERITY.SUCCESS, msg: `Org AuthConfig updated successfully` });
      props.onClose();
    }
  }
  return (
    <>
      <DialogTitle>
        <IconButton style={{ float: 'right' }} onClick={props.onClose} size="large">
          <CloseIcon sx={dialogClasses.closeIcon} />
        </IconButton>
      </DialogTitle>
      <Grid item xs={12}>
        <MUIDataTable title="" options={scenarioDataTableOptions} columns={columns} data={data} />
        <DialogActions>
          <IconButton
            style={{ float: 'right', background: '#00539D', color: '#ffffff' }}
            onClick={() => {
              setSelectedConfig(null);
              setEditingOrgAuthConfig(true);
            }}
            size="large"
          >
            <AddIcon />
          </IconButton>
        </DialogActions>
        <DialogActions>
          <Button onClick={() => props.onClose()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onSubmit()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Grid>
      <Dialog open={editingOrgAuthConfig} onClose={() => onClearOrgAuthConfig()} maxWidth="sm" fullWidth>
        <AuthConfigModal
          onClose={() => onClearOrgAuthConfig()}
          editingId={props.editingId}
          initialValues={selectedConfig === null ? DefaultOrgAuthConfig : orgAuthConfigs[selectedConfig]}
          isUpdate={selectedConfig != null}
          onSubmit={onAddConfig}
        />
      </Dialog>
      <Dialog maxWidth="sm" fullWidth open={removeOrgAuthConfig}>
        <DialogTitle id="alert-dialog-title">{'Remove RoleMapping'}</DialogTitle>
        <DialogContent>Are you sure you want to delete</DialogContent>
        <DialogActions>
          <Button onClick={() => onClearOrgAuthConfig()} color="primary">
            No
          </Button>
          <Button color="primary" autoFocus onClick={() => onRemoveConfig()}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography } from '@mui/material';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ColumnAttributes, DataTable, DataTableProps } from 'ui-lib';
import DeleteIcon from '@mui/icons-material/Delete';

import { ClientLogger } from 'lib/client-logger';
import { EditPersonOrg } from './EditPersonOrg';
import { personsQuery, Role } from 'lib/api/api-types';
import { useErrorHandler } from 'lib/use-error-handler';
import { SelectorIdName, SelectorNoForm } from 'ui-lib';
import { UserContext } from 'lib/auth/user-context';
import { usePersonOrgService, Person } from 'lib/api/use-person-org-serivce';
import { Loading } from 'components/widgets/Loading';
import { AddPersonOrg } from './AddPersonOrg';
import { TopBackBtnBar } from '../../components/navigation/TopBackBtnBar';
import makeStyles from '@mui/styles/makeStyles';
import { tableClasses } from '../../style/sharedCssClasses';
interface ButtonColumnData {
  id: string;
  personId: string;
  email: string;
}

export interface DisplayRowType extends Person {
  role: Role | undefined;
  buttons: ButtonColumnData;
}

const useClasses = makeStyles({ ...tableClasses });

const DEBUG = false;

export const PersonOrgList = () => {
  const classes = useClasses();
  const personOrgService = usePersonOrgService();
  const userContext = useContext(UserContext);
  const params = useParams();
  const errorHandler = useErrorHandler('PersonOrgList');
  const specifiedOrgId = params.orgId;
  const [editOpen, setEditOpen] = useState<false | undefined | string>(false); //false not open, undefined open with new, string orgId beiong edited
  const [removeOpen, setRemoveOpen] = useState<false | ButtonColumnData>(false); //false not open, undefined open with new, string orgId beiong edited
  const [currentOrgId, setCurrentOrgId] = useState<string | undefined>(specifiedOrgId || userContext.$.currentOrgId());
  const [orgsList, setOrgsList] = useState(userContext.$.getOrgs());
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const orgs = userContext.$.getOrgs();
    const currentId = userContext.$.currentOrgId();
    setOrgsList(orgs);
    if (currentId && orgs.find((org) => org.id === currentOrgId) === undefined) {
      setReload(true);
      setCurrentOrgId(currentId);
    }
  }, [userContext]);

  useEffect(() => {
    if (currentOrgId) {
      setReload(false);
    }
  }, [currentOrgId]);

  const dataTableOptions: MUIDataTableOptions = {
    pagination: true,
    download: true,
    print: true,
    filter: false,
    search: false,
    viewColumns: false,
    sort: true,
    selectableRows: 'none',
  };
  const columns: ColumnAttributes = {
    title: { display: 'excluded' },
    firstName: { label: 'First Name' },
    middleName: { display: 'excluded' },
    lastName: { label: 'Last Name' },
    updatedAt: { display: 'excluded' },
    gender: { display: 'excluded' },
    dateOfBirth: { display: 'excluded' },
    createdAt: { label: 'Created At' },
    status: { label: 'Status' },
    personOrgs: { display: 'excluded' },
    id: { display: 'excluded' },
    attributes: { display: 'excluded' },
    currentOrg: { display: 'excluded' },
    role: { label: 'Role' },
    isTest: {
      label: 'Test',
      customBodyRender: (value) => {
        return <>{`${value}`}</>;
      },
    },
    systemAccount: {
      label: 'System Account',
      customBodyRender: (value) => {
        return <>{`${value !== null ? value : false}`}</>;
      },
    },
    buttons: {
      label: '',
      customBodyRender: (value: ButtonColumnData) => {
        if (value !== undefined && value !== null) {
          return (
            <Box display="flex" justifyContent="space-evenly" alignItems="start">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  DEBUG && ClientLogger.debug('custom render click', '', value);
                  setRemoveOpen(value);
                }}
                color="primary"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          );
        } else {
          return <></>;
        }
      },
      customHeadRender: () => <td key="edit"></td>,
    },
  };

  const transformResponseToRows = (resp: personsQuery | undefined): DisplayRowType[] => {
    if (!resp || !resp.persons || !resp.persons?.edges) {
      return [];
    }
    return resp.persons.edges.map((person) => {
      const personOrg = personOrgService.personOrgCalc(person.node, currentOrgId);
      return {
        ...person.node,
        role: person.node.personOrgs.find((n) => n.orgId === currentOrgId)?.role,
        buttons: { id: personOrg?.id || '', personId: personOrg?.personId || '', email: personOrg?.email || '' },
      };
    });
  };

  type NewType = DataTableProps<string, any, personsQuery, DisplayRowType>;
  const rowClicked = (row: any) => {
    const personOrg = personOrgService.personOrgCalc(row, currentOrgId);
    if (personOrg) {
      setEditOpen(personOrg.id);
    }
  };

  const tableProps: NewType = {
    useQuery: personOrgService.usePersonsQuery({ errorPolicy: 'all', variables: { query: { orgId: currentOrgId || '' } } }) as any, // https://github.com/tannerlinsley/react-query/issues/1675
    initialQuery: {},
    transformResponseToRows,
    columnAttributes: columns,
    tableAttributes: dataTableOptions,
    onAdd: () => {
      DEBUG && ClientLogger.debug('onAdd', '', {});
      setEditOpen(undefined);
    },
    onRowClick: rowClicked,
  };

  DEBUG && ClientLogger.debug('PersonList', 'render', { tableProps });

  const orgsSelectList: SelectorIdName[] = orgsList.map((org) => ({ id: org.id, name: org.name }));

  const setSelectedOrgId = (orgId: string) => {
    setCurrentOrgId(orgId);
    userContext.$.saveCurrentOrgId(orgId);
  };

  async function removePersonOrg(personOrgId: string) {
    await personOrgService
      .personOrgDelete(personOrgId)
      .then((resp) => {
        if (resp.errors) {
          errorHandler.handleErrors({ graphQLErrors: resp.errors });
        }
      })
      .catch((err) => {
        errorHandler.handleErrors({ error: err });
      });
  }

  async function removePerson(personId: string) {
    await personOrgService
      .personDelete(personId)
      .then((resp) => {
        if (resp.errors) {
          errorHandler.handleErrors({ graphQLErrors: resp.errors });
        }
      })
      .catch((err) => {
        errorHandler.handleErrors({ error: err });
      });
  }

  if (reload) {
    return <Loading />;
  }
  return (
    <Grid container>
      <TopBackBtnBar title="People" />
      <Box mx={2} mt={6} mb={1} className={classes.tableHolder}>
        {!specifiedOrgId && <SelectorNoForm items={orgsSelectList} value={currentOrgId} onChange={(item) => setSelectedOrgId(item.id)} />}
        {!currentOrgId ? (
          <Loading />
        ) : (
          <>
            <DataTable {...tableProps} />
            <Dialog open={!(editOpen === false)} onClose={() => setEditOpen(false)} maxWidth="sm" fullWidth>
              {editOpen === undefined ? (
                <AddPersonOrg
                  onClose={() => {
                    DEBUG && ClientLogger.debug('AddPersonOrg', 'onClose', {});
                    setEditOpen(false);
                  }}
                  orgId={currentOrgId}
                />
              ) : (
                <EditPersonOrg
                  onClose={() => {
                    setEditOpen(false);
                  }}
                  editingId={editOpen || ''}
                  orgId={currentOrgId}
                />
              )}
            </Dialog>
            <Dialog open={!!removeOpen} onClose={() => setRemoveOpen(false)} maxWidth="sm" fullWidth>
              <DialogTitle>Remove {removeOpen && removeOpen.email}</DialogTitle>
              <DialogContent>
                <Typography variant="body1">Are you sure you want to remove {removeOpen && removeOpen.email}?</Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setRemoveOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={(item) => {
                    DEBUG && ClientLogger.debug('PersonOrgList', 'person-org remove related', { item });
                    setRemoveOpen(false);
                    if (removeOpen && removeOpen.id) {
                      removePersonOrg(removeOpen.id);
                    }
                  }}
                >
                  Remove From Org
                </Button>
                <Button
                  onClick={(item) => {
                    DEBUG && ClientLogger.debug('PersonOrgList', 'person remove related', { item });
                    setRemoveOpen(false);
                    if (removeOpen && removeOpen.personId) {
                      removePerson(removeOpen.personId);
                    }
                  }}
                >
                  Remove Person
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Box>
    </Grid>
  );
};

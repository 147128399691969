import { Typography } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';

interface IProps {
  name?: string;
  fullWidth?: boolean;
}

export function Identifier(props: IProps) {
  return (
    <Field
      component={TextField}
      type={'text'}
      label="Identifier"
      name={props.name}
      variant="outlined"
      data-test="input-identifier"
      fullWidth={props.fullWidth || false}
      autocapitalize="none"
      onKeyPress={(e: any) => {
        console.log(e.key);
        // Prevent anything except letters, numbers, hyphen and underscore from being entered
        if (!e.key.match(/^[a-zA-Z0-9-_]$/)) {
          e.preventDefault();
        }
      }}
      inputProps={{ pattern: '[a-z]{1,15}' }}
    />
  );
}

import { Box, Button, DialogActions, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { Loading } from 'components/widgets/Loading';
import { Field, Formik, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import { PersonVerification, PersonVerificationIdType, PersonVerificationInput, Role, VerificationMethodType } from 'lib/api/api-types';
import { UserContext } from 'lib/auth/user-context';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { usePersonOrgService } from 'lib/api/use-person-org-serivce';
import { SEVERITY, ToastDispatchContext, EmailOnlyField, VerificationSubFields, VerificationMethodSelector, IdTypeItem } from 'ui-lib';
import { RoleSelector } from 'ui-lib';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Clear';
import { dialogClasses } from '../../style/sharedCssClasses';
import { getVeridApolloClient } from 'lib/api/apollo-client';

interface IValues {
  title: string | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  gender: string | null;
  dateOfBirth: any | null;
  id: string;
  email: string | null;
  role: Role;
  verification?: VerificationSubFields;
  idTypes?: IdTypeItem[];
  isSysAccount: boolean;
}

interface IProps {
  editingId: string;
  orgId: string;
  onClose: () => void;
}

const useClasses = makeStyles({
  ...dialogClasses,
});

const DEBUG = false;

export function EditPersonOrg(props: IProps) {
  const classes = useClasses();
  const personOrgService = usePersonOrgService();
  const errorHandler = useErrorHandler('EditOrg');
  const formRef = useRef<FormikProps<IValues>>(null);
  const [loading, setLoading] = useState(true);
  const [editingPersonDetails, setEditingPersonDetails] = useState<boolean>(false);
  const [viewPersonVerifications, setViewPersonVerifications] = useState<boolean>(false);
  const [editingRole, setEditingRole] = useState<boolean>(false);
  const [editingEmail, setEditingEmail] = useState<boolean>(false);
  const [personVerifications, setPersonVerifications] = useState<any>([]);
  const toastDispatch = useContext(ToastDispatchContext);
  const veridApolloClient = getVeridApolloClient();
  const { editingId, onClose, orgId } = props;

  useEffect(() => {
    DEBUG && ClientLogger.debug('EditPersonOrg', 'useEffect', { props });
    if (formRef.current) {
      if (editingId) {
        personOrgService
          .personOrg(editingId)
          .then((resp) => {
            const personOrg = resp.data?.personOrg; //Util.convertBlanks(resp.data.personOrg, null, '');
            DEBUG && ClientLogger.debug('EditPersonOrg', 'load personOrg', { resp, personOrg });
            if (personOrg) {
              formRef.current?.setValues({
                id: personOrg.id,
                role: personOrg.role,
                email: personOrg.email,
                title: personOrg.person.title,
                firstName: personOrg.person.firstName,
                middleName: personOrg.person.middleName,
                lastName: personOrg.person.lastName,
                gender: personOrg.person.gender,
                dateOfBirth: personOrg.person.dateOfBirth,
                isSysAccount: personOrg.person.systemAccount || false,
                verification: undefined,
                idTypes: [],
              });
              personOrgService
                .personVerifications(personOrg.orgId, personOrg.person.id)
                .then((response) => {
                  const personVerificationsData = response.data?.personVerifications;
                  if (personVerificationsData) {
                    setPersonVerifications(personVerificationsData);
                  } else {
                    errorHandler.handleErrors({ graphQLErrors: response.errors });
                  }
                })
                .catch((error) => {
                  errorHandler.handleErrors({ error: error });
                  setLoading(false);
                });
            } else {
              errorHandler.handleErrors({ graphQLErrors: resp.errors });
            }
            setLoading(false);
          })
          .catch((err) => {
            errorHandler.handleErrors({ error: err });
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  }, [editingId]);

  const scenarioDataTableOptions: MUIDataTableOptions = {
    pagination: false,
    download: false,
    print: false,
    filter: false,
    search: false,
    viewColumns: false,
    sort: true,
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: 'No Mapping Found',
      },
    },
  };

  const columns: MUIDataTableColumnDef[] = [
    { name: 'createdAt', label: 'Created At' },
    { name: 'createdBy', label: 'Created By' },
    { name: 'methodType', label: 'Method Type' },
    { name: 'personId', label: 'Person ID' },
    { name: 'firstName', label: 'First Name' },
    { name: 'lastName', label: 'Last Name' },
    { name: 'status', label: 'Status' },
  ];

  const data: any = personVerifications.map((personVerification: PersonVerification) => ({
    createdAt: personVerification.createdAt,
    createdBy: personVerification.createdBy.email,
    methodType: personVerification.method.type,
    personId: personVerification.person.id,
    firstName: personVerification.firstName,
    lastName: personVerification.lastName,
    status: personVerification.person.status,
  }));

  async function savePerson(values: IValues) {
    if (!values.verification?.passed) {
      toastDispatch({
        severity: SEVERITY.ERROR,
        msg: 'Verification method must be selected and verified',
      });
      return;
    }

    if (values.verification) {
      const verification = values.verification;

      const verificationInput: PersonVerificationInput = {
        methodId: verification.id,
        passed: verification.passed,
        idTypes:
          values.idTypes && values.idTypes.length > 0
            ? values.idTypes.map((idType) => ({
                id: idType.id,
                idNumber: idType.idNumber,
                idExpiry: idType.idExpiry,
              }))
            : undefined,
        title: values.title,
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
      };

      const resp = await personOrgService.personUpdate({
        personOrgId: values.id,
        verification: verificationInput,
      });
      if (resp.data?.personUpdate.id) {
        toastDispatch({
          severity: SEVERITY.SUCCESS,
          msg: 'Person updated',
        });
        onClose();
      } else {
        errorHandler.handleMutateResponse(resp);
      }
    }
  }

  async function updatePersonOrgRole(values: IValues) {
    const resp = await personOrgService.personOrgChangeRole({
      id: values.id,
      orgId: orgId,
      role: values.role,
    });
    if (resp.data?.personOrgChangeRole.success) {
      toastDispatch({
        severity: SEVERITY.SUCCESS,
        msg: 'User Updated',
      });
      setEditingRole(false);
      onClose();
    } else {
      errorHandler.handleMutateResponse(resp);
    }
  }

  DEBUG && ClientLogger.debug('EditPersonOrg', 'render', { props, formRef: JSON.stringify(formRef.current) });
  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        id: '',
        role: Role.USER,
        email: '',
        title: '',
        firstName: '',
        middleName: '',
        lastName: '',
        dateOfBirth: undefined,
        gender: '',
        isSysAccount: false,
        verification: {
          id: '',
          idTypes: [],
          passed: false,
          verificationMethodType: VerificationMethodType.PERSONAL_KNOWLEDGE,
        },
        idTypes: [],
      }}
      validate={(values: IValues) => {
        let errors: Partial<IValues> = {};
        return errors;
      }}
      onSubmit={() => {}}
    >
      {({ submitForm, isSubmitting, errors, values, setFieldValue, touched, setFieldError, setFieldTouched, dirty }) => {
        if (loading) {
          return <Loading />;
        }
        return (
          <>
            <DialogTitle className={classes.dialogTitle}>
              Edit Person
              <IconButton onClick={onClose} size="large">
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {editingPersonDetails === false ? (
                <Box>
                  <Typography variant="h6">Person Details</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={9}>
                      <Typography variant="body2">Title: {values.title}</Typography>
                      <Typography variant="body2">First Name: {values.firstName}</Typography>
                      <Typography variant="body2">Middle Name: {values.middleName}</Typography>
                      <Typography variant="body2">Last Name: {values.lastName}</Typography>
                      <Typography variant="body2">Gender: {values.gender}</Typography>
                      <Typography variant="body2">Date of Birth: {values.dateOfBirth}</Typography>
                      {values.isSysAccount && <Typography variant="body2">System Account: {`${values.isSysAccount}`}</Typography>}
                    </Grid>
                    <Grid item xs={3}>
                      <Button variant="contained" className={classes.btnSpacing} onClick={() => setEditingPersonDetails(true)}>
                        Edit
                      </Button>
                      <Button variant="contained" className={classes.btnSpacing} onClick={() => setViewPersonVerifications(true)}>
                        History
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <>
                  <VerificationMethodSelector
                    baseName="verification"
                    baseNameIdTypes="idTypes"
                    orgId={props.orgId}
                    setFieldValue={setFieldValue}
                    apolloClientVerid={veridApolloClient}
                    readFromVerid
                    style={{
                      inputField: {
                        width: '100%',
                        marginTop: '12px',
                      },
                    }}
                  />
                  <Field
                    component={TextField}
                    name="title"
                    label="Title"
                    placeholder="Enter the tite (Mr. Ms. etc)"
                    type="text"
                    fullWidth
                    variant="outlined"
                    className={classes.inputField}
                    data-test="name-textfield"
                  />
                  <Field
                    component={TextField}
                    name="firstName"
                    label="First Name"
                    placeholder="Enter the first name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    className={classes.inputField}
                    data-test="new-patient-middle-name-textfield"
                  />
                  <Field
                    component={TextField}
                    name="middleName"
                    label="Middle Name"
                    placeholder="Enter the middle name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    className={classes.inputField}
                    data-test="name-textfield"
                  />
                  <Field
                    component={TextField}
                    name="lastName"
                    label="Last Name"
                    placeholder="Enter the last name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    className={classes.inputField}
                    data-test="name-textfield"
                  />

                  <DialogActions>
                    <Button color="primary" disabled={isSubmitting} onClick={() => setEditingPersonDetails(false)}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={() => {
                        savePerson(values);
                      }}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </>
              )}
              {editingRole === false ? (
                <Box mt={2}>
                  <Typography variant="h6">Role</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={9}>
                      <Typography variant="body2">Role: {values.role}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Button variant="contained" onClick={() => setEditingRole(true)}>
                        Edit
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <>
                  <RoleSelector name="role" />
                  <DialogActions>
                    <Button color="primary" disabled={isSubmitting} onClick={() => setEditingRole(false)}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={() => {
                        updatePersonOrgRole(values);
                      }}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </>
              )}
              {editingEmail === false ? (
                <Box mt={2}>
                  <Typography variant="h6">Email</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={9}>
                      <Typography variant="body2">Email Address: {values.email}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Button variant="contained" onClick={() => alert('to do')}>
                        Edit
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <>
                  <EmailOnlyField />
                  <DialogActions>
                    <Button color="primary" disabled={isSubmitting} onClick={() => setEditingRole(false)}>
                      Cancel
                    </Button>
                    <Button type="submit" color="primary" disabled={isSubmitting}>
                      Save
                    </Button>
                  </DialogActions>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button color="primary" disabled={isSubmitting} onClick={() => onClose()}>
                Done
              </Button>
            </DialogActions>
            <Dialog open={viewPersonVerifications} onClose={() => setViewPersonVerifications(false)} maxWidth="lg" fullWidth>
              <>
                <MUIDataTable title="Person Verifications History" options={scenarioDataTableOptions} columns={columns} data={data} />
                <DialogActions>
                  <Button color="primary" disabled={isSubmitting} onClick={() => setViewPersonVerifications(false)}>
                    Back To Edit Person
                  </Button>
                </DialogActions>
              </>
            </Dialog>
          </>
        );
      }}
    </Formik>
  );
}

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { Loading } from 'components/widgets/Loading';
import { Org, useOrgService } from 'lib/api/use-org-service';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import React, { useEffect, useState } from 'react';
import { dialogClasses } from '../../style/sharedCssClasses';
import CloseIcon from '@mui/icons-material/Clear';
import { EditOrgOverview } from './EditOrgOverview';
import { EditExtMessageConfig } from './EditExtMessageConfig';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTES } from 'Routes';
import { OrgRelation, OrgRelationSearchInput, OrgType } from 'lib/api/api-types';
import { OrgRelationList } from './OrgRelationList';
import { ManageOrgApiKeys } from './ManageOrgApiKeys';
import { AuthConfigList } from './AuthConfigList';
import { EditOrgDomain } from './EditOrgDomain';

interface IProps {
  editingId: string;
  onClose: () => void;
}

const DEBUG = false;

interface RelatedOrgDisplay extends OrgRelationSearchInput {
  // orgType: OrgType;
  title: string;
}

export function OrgDetails(props: IProps) {
  const orgService = useOrgService();
  const errorHandler = useErrorHandler('OrgDetails');
  const [loading, setLoading] = useState(true);
  const [org, setOrg] = useState<Org | undefined>(undefined);
  const [editingOverview, setEditingOverview] = useState(false);
  const [editingOrgDomain, setEditingOrgDomain] = useState(false);
  const [authConfigList, setAuthConfigList] = useState(false);
  const [managingApiKeys, setManagingApiKeys] = useState(false);
  const [editingExtMessage, setEditingExtMessage] = useState(false);
  const [reload, setReload] = useState(0);
  const [relatedOrgs, setRelatedOrgs] = useState<RelatedOrgDisplay | undefined>(undefined);
  const dialogTitle = org?.name ?? 'Org Details';

  useEffect(() => {
    if (props.editingId) {
      orgService
        .org(props.editingId)
        .then((resp) => {
          const { data } = resp;
          const respOrg = data && data.org;
          DEBUG && ClientLogger.debug('EditOrg', 'load org', { resp, respOrg, org });
          if (respOrg) {
            setOrg({ ...respOrg }); // copy to avoid mutating the original and causing an apollo error
          } else {
            errorHandler.handleErrors({ graphQLErrors: resp.errors });
          }
          setLoading(false);
        })
        .catch((err) => {
          errorHandler.handleErrors({ error: err });
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [props.editingId, reload]);

  DEBUG && ClientLogger.debug('EditOrg', 'render', { props, org });
  if (loading || !org) {
    return <Loading />;
  }

  return (
    <>
      <DialogTitle sx={dialogClasses.dialogTitle}>
        {dialogTitle}
        <IconButton onClick={props.onClose} size="large">
          <CloseIcon sx={dialogClasses.closeIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Organization Details</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body1">
              Name: <strong>{org.name}</strong>
            </Typography>
            <Typography variant="body1">Description: {org.description ? org.description : ''}</Typography>
          </Grid>
          <Grid item xs={3}>
            <DialogActions>
              <Button color="primary" onClick={() => setEditingOverview(true)} variant="contained">
                Edit
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button color="primary" sx={dialogClasses.btnSpacing} onClick={() => setAuthConfigList(true)} variant="contained" fullWidth>
            Configure Authentication
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button sx={dialogClasses.btnSpacing} color="primary" variant="contained" onClick={() => setEditingOrgDomain(true)} fullWidth>
            Edit Org Domain
          </Button>
        </Grid>
        {org.type === OrgType.APP && (
          <Grid item xs={12}>
            <Button color="primary" sx={dialogClasses.btnSpacing} onClick={() => setManagingApiKeys(true)} variant="contained" fullWidth>
              Manage API Keys
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button color="primary" sx={dialogClasses.btnSpacing} onClick={() => setEditingExtMessage(true)} variant="contained" fullWidth>
            Configure External Messages
          </Button>
        </Grid>
        {org.type === OrgType.JURISDICTION && (
          <Button
            color="primary"
            sx={dialogClasses.btnSpacing}
            onClick={() => setRelatedOrgs({ title: 'Provider Organizations', type: OrgRelation.PROVIDER1_JURISDICTION2, org2Id: org.id })}
            variant="contained"
            fullWidth
          >
            Organizations
          </Button>
        )}
        {org.type === OrgType.APP && (
          <Button
            color="primary"
            sx={dialogClasses.btnSpacing}
            onClick={() => setRelatedOrgs({ title: 'Deployed at Organizations', type: OrgRelation.PROVIDER1_APP2, org2Id: org.id })}
            variant="contained"
            fullWidth
          >
            Deployed at Organizations
          </Button>
        )}
        {org.type === OrgType.PROVIDER && (
          <>
            <Button
              color="primary"
              sx={dialogClasses.btnSpacing}
              onClick={() => setRelatedOrgs({ title: 'App Organizations', type: OrgRelation.PROVIDER1_APP2, org1Id: org.id })}
              variant="contained"
              fullWidth
            >
              Connected App Organizations
            </Button>
            <Button
              color="primary"
              sx={dialogClasses.btnSpacing}
              onClick={() =>
                setRelatedOrgs({ title: 'Member of Jurisdictions', type: OrgRelation.PROVIDER1_JURISDICTION2, org1Id: org.id })
              }
              variant="contained"
              fullWidth
            >
              Jurisdictions
            </Button>
          </>
        )}
        <Grid item xs={12}>
          <Button
            sx={dialogClasses.btnSpacing}
            component={RouterLink}
            color="primary"
            to={ROUTES.PERSON_ORG_LIST}
            state={{ orgId: props.editingId }}
            variant="contained"
            fullWidth
          >
            People
          </Button>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => props.onClose()}>
          Done
        </Button>
      </DialogActions>

      <Dialog open={editingOrgDomain} onClose={() => setEditingOrgDomain(false)} maxWidth="sm" fullWidth>
        <EditOrgDomain
          emailDomain={org.emailDomain}
          onClose={() => {
            setEditingOrgDomain(false);
            setReload(reload + 1);
          }}
          editingId={org.id}
        />
      </Dialog>
      <Dialog open={editingOverview} onClose={() => setEditingOverview(false)} maxWidth="sm" fullWidth>
        <EditOrgOverview
          orgType={org.type}
          initialValues={{ name: org.name, description: org.description || '', id: org.id }}
          onClose={() => {
            setEditingOverview(false);
            setReload(reload + 1);
          }}
          editingId={org.id}
        />
      </Dialog>
      <Dialog open={authConfigList} onClose={() => setAuthConfigList(false)} maxWidth="md" fullWidth>
        <AuthConfigList
          orgType={org.type}
          initialValues={org.authConfig}
          onClose={() => {
            setAuthConfigList(false);
            setReload(reload + 1);
          }}
          editingId={org.id}
        />
      </Dialog>
      <Dialog open={managingApiKeys} onClose={() => setManagingApiKeys(false)} maxWidth="lg" fullWidth>
        <ManageOrgApiKeys
          orgType={org.type}
          onClose={() => {
            setManagingApiKeys(false);
          }}
          orgId={org.id}
        />
      </Dialog>
      <Dialog open={editingExtMessage} onClose={() => setEditingExtMessage(false)} maxWidth="sm" fullWidth>
        <EditExtMessageConfig
          orgName={org.name}
          initialValues={org.extMessageConfig}
          onClose={() => {
            setEditingExtMessage(false);
          }}
          editingId={org.id}
        />
      </Dialog>
      <Dialog open={!!relatedOrgs} onClose={() => setRelatedOrgs(undefined)} maxWidth="sm" fullWidth>
        {relatedOrgs && (
          <>
            <DialogTitle>
              {relatedOrgs.title}
              <IconButton
                aria-label="close"
                onClick={() => setRelatedOrgs(undefined)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <OrgRelationList
              orgRelation={relatedOrgs?.type}
              org1Id={relatedOrgs.org1Id || undefined}
              org2Id={relatedOrgs.org2Id || undefined}
            />
          </>
        )}
      </Dialog>
    </>
  );
}

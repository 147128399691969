import { OrgAuthConfigType } from 'verid-shared-lib';
import * as Yup from 'yup';

export const ValidateSchema = Yup.object().shape({
  authName: Yup.string().required('Required'),
  veridRoles: Yup.array().of(Yup.string().required('Required')).required('Required'),
  authConfig: Yup.object().shape({
    type: Yup.string().required('Required'),
    clientId: Yup.string().when('type', {
      is: (type) => type === OrgAuthConfigType.OPEN_ID,
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),
    authorizationEndpoint: Yup.string().when('type', {
      is: (type) => type === OrgAuthConfigType.OPEN_ID,
      then: Yup.string()
        .required('Required')
        .matches(
          /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=\/]{2,256}(\/authorize)$/,
          'Provide valid Authorization Endpoint. Ex: https://<domain>/authorize'
        ),
      otherwise: Yup.string().nullable(),
    }),
    tokenEndpoint: Yup.string().when('type', {
      is: (type) => type === OrgAuthConfigType.OPEN_ID,
      then: Yup.string()
        .required('Required')
        .matches(/^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=\/]{2,256}(\/token)$/, 'Provide valid Token Endpoint. Ex: https://<domain>/token'),
      otherwise: Yup.string().nullable(),
    }),
    userInfoEndpoint: Yup.string().when('type', {
      is: (type) => type === OrgAuthConfigType.OPEN_ID,
      then: Yup.string()
        .required('Required')
        .matches(
          /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=\/]{2,256}(\/userinfo)$/,
          'Provide valid User Info Endpoint. Ex: https://<domain>/userinfo'
        ),
      otherwise: Yup.string().nullable(),
    }),
    buttonName: Yup.string().when('type', {
      is: (type) => type === OrgAuthConfigType.OPEN_ID,
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),
    loginPageSlug: Yup.string().when('type', {
      is: (type) => type === OrgAuthConfigType.OPEN_ID,
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),
    identityProviderId: Yup.string().when('type', {
      is: (type) => type === OrgAuthConfigType.IDENTITY_PROVIDER,
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),
    identityProviderName: Yup.string().when('type', {
      is: (type) => type === OrgAuthConfigType.IDENTITY_PROVIDER,
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),
    identityProviderKeyId: Yup.string().when('type', {
      is: (type) => type === OrgAuthConfigType.IDENTITY_PROVIDER,
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),
    identityProviderClientId: Yup.string().when('type', {
      is: (type) => type === OrgAuthConfigType.IDENTITY_PROVIDER,
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),
    reconcileEndpoint: Yup.string().when('type', {
      is: (type) => type === OrgAuthConfigType.IDENTITY_PROVIDER,
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),
    reconcileTokenMode: Yup.boolean().when('type', {
      is: (type) => type === OrgAuthConfigType.IDENTITY_PROVIDER,
      then: Yup.boolean().required('Required'),
      otherwise: Yup.boolean().nullable(),
    }),
    cookieType: Yup.string().when('type', {
      is: (type) => type === OrgAuthConfigType.IDENTITY_PROVIDER,
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),
    cookieName: Yup.string().when('type', {
      is: (type) => type === OrgAuthConfigType.IDENTITY_PROVIDER,
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),
  }),
});

import { ApolloClient, createHttpLink, ApolloLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { TokenRefreshLink } from '../auth/token-refresh-link';
import { UserContextType } from '../auth/user-context';
import { logout } from './use-auth-service';
import { ClientLogger } from '../client-logger';
import { Util } from 'ui-lib';

export interface ApolloClientContext {
  userContext?: UserContextType;
  endPoint?: 'api';
  noAuth?: boolean;
}

export const apiContext = (userContext: UserContextType): ApolloClientContext => {
  return {
    endPoint: 'api',
    userContext,
  };
};

const DEBUG = false;

ClientLogger.log('Loading API', `apollo endpoint for api = ${Util.getEnvVar('REACT_APP_VERID_API')}`);

interface CurrentClient {
  apolloClient: ApolloClient<any>;
}

let singleton: CurrentClient | null = null;

// https://www.apollographql.com/docs/link/composition/
export const getVeridApolloClient = () => {
  if (singleton) {
    DEBUG && ClientLogger.debug('getVeridApolloClient', 'Returning existing apolloClient');
    return singleton.apolloClient;
  }
  DEBUG && ClientLogger.debug('getVeridApolloClient', 'creating new apolloClient');

  const tokenRenewalLink = ApolloLink.from([new TokenRefreshLink()]);

  const errorLink = onError(({ graphQLErrors, networkError, response, operation }) => {
    ClientLogger.error('getVeridApolloClient', 'graphQLErrors', { graphQLErrors, networkError, response, operation });
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions, message, locations, path }) => {
        ClientLogger.error(
          'apollo-client.getVeridApolloClient',
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        if (extensions?.code === 'UNAUTHENTICATED') {
          ClientLogger.error(
            'apollo-client.getVeridApolloClient',
            `detected unauthenticated - why was this not picked up by token-refresh?}`
          );
          // AuthClient.logout(userContext, '/');  // Causes endless loop - need further condition
          logout();
        }
      });
    }
    if (networkError) {
      if (networkError.name && networkError.name.indexOf('Error writing result to store for query') > -1) {
        ClientLogger.log('getVeridApolloClient', 'Error writing result to store for query detected. Assumed to be a faulty cache.');
      }
      ClientLogger.log('getVeridApolloClient', `Network error: ${JSON.stringify(networkError)}`);
    }
  });

  const apiLinks = ApolloLink.from([
    tokenRenewalLink,
    errorLink,
    createHttpLink({
      uri: Util.getEnvVar('REACT_APP_VERID_API', DEBUG),
      credentials: 'include',
    }),
  ]);

  singleton = {
    apolloClient: new ApolloClient({
      link: apiLinks,
      cache: new InMemoryCache({ typePolicies: { Form: { keyFields: ['name'] } } }),
      connectToDevTools: true,
    }),
  };
  return singleton?.apolloClient;
};

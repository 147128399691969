import React, { useRef } from 'react';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { FormContainer } from 'components/form/FormContainer';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { Selector, Util } from 'ui-lib';
import CloseIcon from '@mui/icons-material/Clear';
import { dialogClasses } from '../../style/sharedCssClasses';
import { DefaultAuthConfig, OrgAuthTypes, OrgAuthConfigType, OrgAuthConfig } from 'verid-shared-lib';
import { EditAuthConfig } from './EditAuthConfig';
import { ValidateSchema } from '../../lib/AuthConfigValidateSchema';

const DEBUG = true;

function AuthConfigTypeSelector(props: {
  baseName: string;
  name: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}) {
  const choices: any = Util.idNamePairsFromEnum(OrgAuthConfigType);

  return (
    <Selector
      name={props.name}
      items={choices}
      label="Authentication Type"
      onChange={(type) => props.setFieldValue(`${props.baseName}`, DefaultAuthConfig[type])}
    />
  );
}

function VeridRoleTypeSelector(props: {
  name: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}) {
  const choices: any = Util.idNamePairsFromEnum(OrgAuthTypes);

  return <Selector name={props.name} items={choices} label="Verid Role" selectMultiple={true} />;
}

export const AuthConfigModal = (props: {
  editingId: string;
  isUpdate: boolean;
  initialValues: OrgAuthConfig;
  onSubmit: (value: any, isUpdateConfig: boolean) => void;
  onClose: () => void;
}) => {
  const formikRef = useRef<any>();

  return (
    <>
      <Formik
        initialValues={props.initialValues}
        onSubmit={async (values, actions) => {
          props.onSubmit(values, props.isUpdate);
        }}
        validationSchema={ValidateSchema}
        innerRef={formikRef}
      >
        {({ submitForm, values, setFieldValue, errors, touched, validateForm, setFieldTouched }) => {
          return (
            <Box>
              <IconButton style={{ float: 'right' }} onClick={props.onClose} size="large">
                <CloseIcon sx={dialogClasses.closeIcon} />
              </IconButton>
              <FormContainer>
                <DialogTitle>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5">{props.isUpdate ? ' Update Org AuthConfig' : 'Create Org AuthConfig'}</Typography>
                  </Box>
                </DialogTitle>
                <DialogContent>
                  <Box my={1}>
                    <Field
                      component={TextField}
                      name="authName"
                      label={`Auth Name`}
                      placeholder="Enter AuthName"
                      fullWidth={true}
                      variant="outlined"
                    />
                  </Box>
                  <Grid item xs={12}>
                    <VeridRoleTypeSelector name="veridRoles" setFieldValue={setFieldValue} />
                  </Grid>
                  <Grid item xs={12}>
                    <AuthConfigTypeSelector baseName="authConfig" name="authConfig.type" setFieldValue={setFieldValue} />
                    {values.authConfig && values.authConfig.type && (
                      <EditAuthConfig
                        baseName="authConfig"
                        setFieldValue={setFieldValue}
                        values={values.authConfig}
                        editingId={props.editingId}
                      />
                    )}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Button color="primary" onClick={() => props.onClose()}>
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      {' '}
                      {props.isUpdate ? ' Update' : 'Create'}
                    </Button>
                  </Box>
                </DialogActions>
              </FormContainer>
            </Box>
          );
        }}
      </Formik>
    </>
  );
};
